export const ro = {
    "login": "Autentificare",
    "register": "Inregistreaza un cont nou",
    "change_password": "Schimbati parola",
    "copyright": "Copyright",
    "copyright_value": "Siniat SEE",
    "email_address": "Adresa de email",
    "address": "Adresa",
    "locality": "Localitatea",
    "language": "Limba",
    "state": "Judetul",
    "password": "Parola",
    "forgot_password": "Ai uitat parola?",
    "enter_account": "Intra in cont",
    "not_registered": "Inregistreaza cont nou",
    "walls": "Pereti",
    "linnings": "Placari",
    "linnings_f": "Placari cu fixari",
    "linnings_i": "Placari independente",
    "linnings_l": "Placari liniare",
    "linnings_p": "Placari lipire",
    "linnings_nf": "Placari Noisy cu fixari",
    "linnings_ni": "Placari Noisy independente",
    "linnings_nuu": "Placari Noisy UU",
    "ceilings": "Plafoane",
    "ceilings_s": "Plafoane suspendate",
    "ceilings_ss": "Plafoane autoportante",
    "home": "Acasa",
    "print": "Printeaza",
    "personal_information": "Informatii personale",
    "first_name": "Prenume",
    "last_name": "Nume",
    "last_login": "Ultima logare",
    "logins_number": "Numar autentificari",
    "phone_number": "Numar de telefon",
    "status_active": "Activ",
    "is_admin": "Admin",
    "created_on": "Data inregistrare",
    "account_data": "Datele contului",
    "confirm_password": "Confirma parola",
    "read_agree": "Sunt de acord cu",
    "terms_conditions": "termene si conditii",
    "finish_register": "Inregistreaza contul",
    "already_registered": "Esti deja inregistrat? Autentifica-te",
    "required_field": "Acest camp este obligatoriu",
    "minimum_characters": "Numar minim de caractere este __min__",
    "maximum_characters": "Numar maxim de caractere este __max__",
    "valid_email": "Adresa de email nu este valida",
    "invalid_phone_format": "Format invalid",
    "reset_password": "Resetare Parola",
    "reset_password_button": "Recupereaza parola",
    "back_to_login": "Inapoi la autentificare",
    "page_loading": "Pagina se incarca, va rugam asteptati",
    "new_password": "Parola noua",
    "confirm_new_password": "Confirma parola noua",
    "save_password": "Salveaza parola",
    "successfully_registered": "Inregistrare cu succes",
    "successfully_registered_paragraph": "Inregistrarea contului a fost efectuata cu succes",
    "successfully_registered_approval": "Contul dumnevoastra va fi activat in cel mai scurt timp",
    "resend_confirmation": "Retrimite email-ul de confirmare",
    "account_not_activated": "Contul nu este activat",
    "account_activated": "Contul a fost activat",
    "account_activated_description": "Contul a fost activat cu succes",
    "resend_confirm_account_description_1": "Completati adresa de email cu care v-ati inregistrati",
    "resend_confirm_account_description_2": "Odata ce primesti email, apasati pe butonul de Confirmare cont pentru a activa contul",
    "agree_terms_conditions": "Trebuie sa acceptati termenele si conditiile pentru a continua inregistrarea",
    "use_new_password": "Parola s-a resetat. Foloseste noua ta parola pentru autentificare",
    "not_activated": "Nu ati primit email de activare cont? Puteti solicita unul nou aici",
    "resend_confirmation_success": "Daca contul a fost inregistrat cu aceasta adresa de mail, ar trebui sa primesti un link de confirmare. Te rog sa verifici se sectiunea de spam",
    "successfully_requested_reset_password": "Cererea de resetare a parolei a fost trimisa via email. Te rugam verifica inclusiv in folderul spam",
    "saved_password": "Parola a fost salvata. O sa fi redirectionat intr-un timp scurt",
    "january": "ianuarie",
    "february": "februarie",
    "march": "martie",
    "april": "aprilie",
    "may": "mai",
    "june": "iunie",
    "july": "iulie",
    "august": "august",
    "september": "septembrie",
    "october": "octombrie",
    "november": "noiembrie",
    "december": "decembrie",
    "logout": "Iesire",
    "list_empty": "Aceasta lista este goala",
    "attach_image": "Incarca imaginea",
    "file_too_large": "Acest fisier este prea mare",
    "file_wrong_file_type_spreadsheet": "Tipul de fisier nu este suportat. Va rugam sa folositi un fisier de tip .xls, .xlsx",
    "numbers_only": "Numbers only",
    "save": "Save",
    "back": "Inapoi",
    "actions": "Operatii",
    "confirm": "Confirma",
    "cancel": "Anuleaza",
    "close": "Inchide",
    "add_offer": "Adauga Oferta",
    "manage_offers": "Ofertele mele",
    "height": "Inaltime",
    "profile_type": "Tip profil",
    "fire_resistance": "Rezistenta la foc",
    "moisture_resistance": "Rezistenta la umiditate",
    "sound_insulation": "Vata minerala",
    "sound_insulation_value": "Izolare acustica (dB)",
    "sound_proof": "Izolare fonica",
    "back_to_offers": "Inapoi la oferte",
    "support": "Tip suport",
    "finishing": "Finisare",
    "imported_file": "Fisierul a fost prelucrat cu succes",
    "interax": "Interax",
    "price": "Pret (RON)",
    "sap_code": "Cod SAP",
    "product_name": "Nume produs",
    "amount": "Cantitate",
    "square_meter": "mp",
    "unit_of_measure": "UM",
    "project_info": "Informatii Proiect",
    "joint_length": "Lungime rost",
    "boq_code": "Denumire BoQ",
    "system_name": "Denumire sistem",
    "offer_details": "Detaliile ofertei",
    "compatible_offers": "Oferte compatibile criteriilor tale",
    "choose_system": "Alege tipul de sistem",
    "face1Plate1": "Fata1: Placa1",
    "face1Plate2": "Fata1: Placa2",
    "face1Plate3": "Fata1: Placa3",
    "face1Plate4": "Fata1: Placa4",
    "face1Plate5": "Fata1: Placa5",
    "face2Plate1": "Fata2: Placa1",
    "face2Plate2": "Fata2: Placa2",
    "face2Plate3": "Fata2: Placa3",
    "face2Plate4": "Fata2: Placa4",
    "more_details": "Mai multe detalii aici",
    "save_offer": "Salveaza oferta",
    "interax_sustineri": "Interax Sustineri",
    "consumuri": "Necesar de materiale",
    "prindere_superioara": "Necesar de materiale prindere superioara",
    "surface": "Suprafata",
    "delete_offer_title": "Esti sigur ca doresti sa stergi aceasta oferta?",
    "delete_offer_description": "Nu o sa mai aveti oferta salvata dupa ce o stergeti",
    "deleted_session_offer": "Nu o sa mai aveti oferta salvata dupa ce o stergeti",
    "deleted_offer": "Oferta stearsa",
    "delete_session_title": "Esti sigur ca doresti sa stergi acest proiect?",
    "delete_session_description": "Nu o sa mai aveti proiectul salvat dupa ce o stergeti",
    "company": "Organizatie",
    "company_register": "Numele firmei pe care o reprezentati",
    "project_code": "Cod proiect Siniat",
    "job": "Obiectul de activitate",
    "contact_person": "Persoana de contact",
    "email": "Email",
    "objective": "Denumire Obiectiv",
    "type_objective": "Tip Obiectiv",
    "type_objective_option_1": "Bloc locuinte",
    "type_objective_option_2": "Cladire birouri",
    "type_objective_option_3": "Hala industriala sau depozit",
    "type_objective_option_4": "Complex comercial",
    "type_objective_option_5": "Hotel",
    "type_objective_option_6": "Spital",
    "type_objective_option_7": "Divertisment(piscina, cinema, sala concrete etc.)",
    "type_objective_option_8": "Invatamant",
    "location": "Judet",
    "description": "Descriere",
    "validation_date": "Valid pana la data de:",
    "nr": "Nr crt",
    "delete_session": "Sterge proiectul",
    "download_session": "Descarca proiectul",
    "view_session_offers": "Vizualizeaza ofertele proiectului",
    "edit_session": "Editeaza proiectul",
    "create_new_session": "Creeaza un proiect nou",
    "new_offer": "Creaza oferta noua",
    "edit_offer_plates": "Editeaza placile ofertei",
    "finish_edit_plates": "Termina editarea placilor ofertei",
    "import_file": "Prelucreaza fisier",
    "choose_import": "Alege tipul de fisier de calcul",
    "imports": "Import fisiere de calcul",
    "burglary_resistance": "Rezistenta la efractie",
    "number_of_offers": "Numarul de oferte create",
    "date_created": "Data crearii ofertei",
    "back_to_create_offer": "Inapoi la generarea de oferta",
    "generate_offers": "Genereaza oferte compatibile",
    "reset_offer": "Reseteaza campurile ofertei",
    "my_sessions": "Proiectele mele",
    "manage_users": "Administrare utilizatori",
    "reports": "Rapoarte",
    "sessions_offers": "Proiecte / Oferte",
    "save_session": "Salveaza proiectul",
    "download": "Descarca",
    "download_file": "Descarca fisierul",
    "download_users": "Descarca lista",
    "next": "Continua",
    "walls_s": "Simplu Placat",
    "walls_d": "Dublu Placat",
    "walls_t": "Triplu Placat",
    "linnings_s": "Placari Simple",
    "linnings_d": "Placari Duble",
    "linnings_t": "Placari Triple",
    "linnings_q": "Placari Cvadruple",
    "linnings_ei": "Placari EI180 min (5 placi)",
    "ceilings_plates_s": "Plafoane Simple",
    "ceilings_plates_d": "Plafoane Duble",
    "ceilings_plates_t": "Plafoane Triple",
    "ceilings_plates_q": "Plafoane Cvadruple",
    "date_validation": "Data de validare",
    "face": "fata",
    "exterior": "exterioara",
    "and": "si",
    "sistem_weight": "Greutate Sistem",
    "montant_type": "Tip montant",
    "upper_guiding": "Tip ghidaj superior",
    "lower_guiding": "Tip ghidaj inferior",
    "plating_types": "Tip placi",
    "plating_type_face_a": "Tip placi Fata A",
    "plating_type_face_b": "Tip placi Fata B",
    "upper_support": "Tip suport superior",
    "wool_type": "Tip izolatie",
    "thickness_system": "Grosime Sistem",
    "ceilings_distance": "Distanta pana la planseu",
    "ceilings_support": "Tip de sustinere",
    "system_code": "Cod Sistem",
    "protection_direction": "Sens Protectie",
    "sound_insulation_label": "Izolare Acustica",
    "plating_type_lower_face": "Tip placi fata inferioara",
    "plating_type_upper_face": "Tip placi fata superioara",
    "main_structure": "Tip structura principala",
    "secondary_structure": "Tip structura secundara",
    "parameter_structure": "Tip ghidaj perimetral",
    "interax_secondary": "Interax structura secundara",
    "type_intermediate_plate": "Tip placa intermediara",
    "distance": "Distanta dintre structuri",
    "structure_type": "Tip Sustineri",
    "manage_account": "Contul meu",
    "edit_account": "Editeaza datele contul",
    "finish_edit_account": "Salveaza datele contului",
    "edit_password": "Editeaza Parola",
    "edit_password_confirm": "Editeaza parola utilizatorului",
    "edit_password_cancel": "Anuleaza editarea parolei",
    "based_plates": "Placa de baza a sistemului",
    "walls_s_asimetric": "Asimetrici",
    "walls_s_intermediar_d": "Dublu Intermediar",
    "walls_s_intermediar_t": "Triplu Intermediar",
    "intermediate_plate": "Placa Intermediara",
    "too_little": "Valoarea este prea mica",
    "too_much": "Valoarea este prea mare",
    "max_opening": "Deschidere maxima",
    "price_per_unit": "Pret(RON) / mp",
    "rows_per_page": "Randuri pe pagina:",
    "no_range": "Nu respecta intervalul!",
    "no_offers": "Momentan nu exista nici o oferta creata",
    "passwords_must_match": "Parola nu este aceeasi! Va rugam sa o introduceti din nou",
    "description_popup": "Aici regasiti primele 6 cele mai ieftine sisteme configurate conform cerinteleor dumneavoastra. Pe coloane puteti urmari o mare parte dintre particularitatile fiecarui sistem, pentru a putea selecta sistemul dorit. Apasati OK pentru a trece mai departe",
    "search": "Cauta",
    "less_table_cols": "Compacteaza tabelul",
    "report": "Raport",
    "import": "Import",
    "materials": "Consumuri",
    "allowed_plates": "Placi Permise",
    "products": "Produse",
    "ceilings_plates_s_tooltip": "Plafoane cu o placa",
    "ceilings_plates_d_tooltip": "Plafoane cu doua placi",
    "ceilings_plates_t_tooltip": "Plafoane cu trei placi",
    "ceilings_plates_q_tooltip": "Plafoane cu patru placi",
    "separative": "Separativi",
    "linnings_noisy_double": "Placari Noisy Duble",
    "linnings_noisy_triple": "Placari Noisy Triple",
    "price_list": "Lista de preturi",
    "attach_file": "Incarcati fisierul",
    "attach_file_for": "Incarcati fisierul pentru importul de",
    "attach_file_by": "Fisier incarcat de ",
    "attach_file_last_timestamp": "Ultima actualizare pentru __file__ a fost la",
    "attach_file_last_file": "Ultimul fisier incarcat",
    "thickness": "Grosime",
    "choose_project": "Alege un proiect pentru aceasta oferta",
    "create_new_project": "Creeaza proiect nou",
    "quit": "Renunta",
    "unspecified": "nespecificat",
    "product": "Produs",
    "select_project": "Selecteaza proiectul",
    "systemType": "Tip sistem",
    "PDF_details": "Detalii PDF",
    "DWG_details": "Detalii DWG",
    "brochures_sistems": "Brosura sisteme",
    'systemCode': 'Cod sistem',
    'technical_characteristics': 'Caracteristici Tehnice',
    'value': 'Valoare',
    'more_details_here': 'Mai multe detalii aici',
    'create_offer_walls_d_simple': "Creare oferta - Pereti D - Simplu Placat",
    'create_offer_walls_d_double': "Creare oferta - Pereti D - Dublu Placat",
    'create_offer_walls_d_triple': "Creare oferta - Pereti D - Triplu Placat",
    'create_offer_walls_s_double': "Creare oferta - Pereti S - Dublu Placat",
    'create_offer_walls_s_triple': "Creare oferta - Pereti S - Triplu Placat",
    'create_offer_walls_s_asimetric': "Creare oferta - Pereti S - Asimetrici",
    'create_offer_walls_s_double_intermediate': "Creare oferta - Pereti S - Dublu Intermediar",
    'create_offer_walls_s_triple_intermediate': "Creare oferta - Pereti S - Triplu Intermediar",
    'create_offer_walls_sl_simple': "Creare oferta - Pereti SL - Simplu Placat",
    'create_offer_walls_sl_double': "Creare oferta - Pereti SL - Dublu Placat",
    'create_offer_walls_sl_triple': "Creare oferta - Pereti SL - Triplu Placat",
    'create_offer_walls_sla_triple': "Creare oferta - Pereti SLA - Triplu Placat",
    'create_offer_plating_fix_simple': "Creare oferta - Placari cu fixari - Simple",
    'create_offer_plating_fix_double': "Creare oferta - Placari cu fixari - Duble",
    'create_offer_plating_fix_triple': "Creare oferta - Placari cu fixari - Triple",
    'create_offer_plating_fix_quadruple': "Creare oferta - Placari cu fixari - Cvadruple",
    'create_offer_plating_fix_ei': "Creare oferta - Placari cu fixari - EI180 min (5 placi)",
    'create_offer_plating_indep_simple': "Creare oferta - Placari independente - Simple",
    'create_offer_plating_indep_double': "Creare oferta - Placari independente - Duble",
    'create_offer_plating_indep_triple': "Creare oferta - Placari independente - Triple",
    'create_offer_plating_indep_quadruple': "Creare oferta - Placari independente - Cvadruple",
    'create_offer_plating_indep_ei': "Creare oferta - Placari independente - EI180 min (5 placi)",
    'create_offer_plating_liniar_double': "Creare oferta - Placari liniare - Duble",
    'create_offer_plating_liniar_triple': "Creare oferta - Placari liniare - Triple",
    'create_offer_plating_liniar_quadruple': "Creare oferta - Placari liniare - Cvadruple",
    'create_offer_plating_liniar_cv': "Creare oferta - Placari liniare - EI180 min (5 placi)",
    'create_offer_plating_sticking': "Creare oferta - Placari Lipire",
    'create_offer_noisy_plating_fix_triple': "Creare oferta - Placari Noisy cu fixari - Triple",
    'create_offer_noisy_plating_indep_double': "Creare oferta - Placari Noisy independente - Duble",
    'create_offer_noisy_plating_indep_triple': "Creare oferta - Placari Noisy independente - Triple",
    'create_offer_noisy_plating_uu_double': "Creare oferta - Placari Noisy UU - Duble",
    'create_offer_noisy_plating_uu_triple': "Creare oferta - Placari Noisy UU - Triple",
    'create_offer_ceiling_suspended_simple': "Creare oferta - Plafoane Suspendate - Simple",
    'create_offer_ceiling_suspended_double': "Creare oferta - Plafoane Suspendate - Duble",
    'create_offer_ceiling_suspended_triple': "Creare oferta - Plafoane Suspendate - Triple",
    'create_offer_ceiling_suspended_quadruple': "Creare oferta - Plafoane Suspendate - Cvadruple",
    'create_offer_ceiling_autoport_simple': "Creare oferta - Plafoane Autoportante - Simple",
    'create_offer_ceiling_autoport_double': "Creare oferta - Plafoane Autoportante - Duble",
    'create_offer_ceiling_autoport_triple': "Creare oferta - Plafoane Autoportante - Triple",
    'create_offer_ceiling_autoport_quadruple': "Creare oferta - Plafoane Autoportante - Cvadruple",
    'create_offer_walls_smart_simple' : "Creare oferta - Pereti Smart - Simplu Placat",
    'create_offer_walls_smart_double' : "Creare oferta - Pereti Smart - Dublu Placat",
    "download_offer": "Descarca Oferta",
    "delete_offer" : "Sterge Oferta",
    "compatible_offers:" : "Oferte Compatibile",
    "country" : "Tara",
    "dont_show_message": "Nu mai afisa acest mesaj",
    "principal_structure": "Structura principala",
    "secondary_structure": "Structura secundara",
    "beam_type": "Tip montant",
    "change_county": "Schimba Judetele",
    "save_county": "Salveaza Judetele",
    "county": "Judete",
    "access": "Access",
    "direction_0": "Nespecificat",
    "direction_1": "Protectie de jos in sus",
    "direction_2": "Protectie de sus in jos",
    "direction_3": "Protectie in ambele directii",
    "any": "Oricare",
    "one_face": "O fata",
    "one_exterior_face": "O fata exterioara",
    "two_faces": "2 fete",
    "with_mineral_wool": "Cu Vata Minerala",
    "without_mineral_wool": "Fara Vata Minerala",
    "without_finishing": "Fara Finisare",
    "with_finishing": "Cu Finisare",
    "simple_structure": "structura simpla",
    "double_structure": "structura dubla",
    "one_face_cementex": "O fata Cementex",
    "without": "Fara",
    "pereti_smart": "Pereti Smart",
    "nida": "Nida",
    "smart": "Smart",
    "walls_d_simple": "Pereti D - Simplu Placat",
    "walls_d_double": "Pereti D - Dublu Placat",
    "walls_d_triple": "Pereti D - Triplu Placat",
    "walls_s_double": "Pereti S - Dublu Placat",
    "walls_s_triple": "Pereti S - Triplu Placat",
    "walls_s_asimetric": "Pereti S - Asimetrici",
    "walls_s_double_intermediate": "Pereti S - Dublu Intermediar",
    "walls_s_triple_intermediate": "Pereti S - Triplu Intermediar",
    "walls_sl_simple": "Pereti SL - Simplu Placat",
    "walls_sl_double": "Pereti SL - Dublu Placat",
    "walls_sl_triple": "Pereti SL - Triplu Placat",
    "walls_sla_triple": "Pereti SLA - Triplu Placat",
    "plating_fix_simple": "Placari cu fixari - Simple",
    "plating_fix_double": "Placari cu fixari - Duble",
    "plating_fix_triple": "Placari cu fixari - Triple",
    "plating_fix_quadruple": "Placari cu fixari - Cvadruple",
    "plating_indep_simple": "Placari independente - Simple",
    "plating_indep_double": "Placari independente - Duble",
    "plating_indep_triple": "Placari independente - Triple",
    "plating_indep_quadruple": "Placari independente - Cvadruple",
    "plating_indep_ei": "Placari independente - EI180 min (5 placi)",
    "plating_fix_ei": "Placari cu fixari - EI180 min (5 placi)",
    "plating_liniar_double": "Placari liniare - Duble",
    "plating_liniar_triple": "Placari liniare - Triple",
    "plating_liniar_quadruple": "Placari liniare - Cvadruple",
    "plating_liniar_cv": "Placari liniare - EI180 min (5 placi)",
    "plating_sticking": "Placari Lipire",
    "noisy_plating_fix_triple": "Placari Noisy cu fixari - Triple",
    "noisy_plating_indep_double": "Placari Noisy independente - Duble",
    "noisy_plating_indep_triple": "Placari Noisy independente - Triple",
    "noisy_plating_uu_double": "Placari Noisy UU - Duble",
    "noisy_plating_uu_triple": "Placari Noisy UU - Triple",
    "ceiling_suspended_simple": "Plafoane Suspendate - Simple",
    "ceiling_suspended_double": "Plafoane Suspendate - Duble",
    "ceiling_suspended_triple": "Plafoane Suspendate - Triple",
    "ceiling_suspended_quadruple": "Plafoane Suspendate - Cvadruple",
    "ceiling_autoport_simple": "Plafoane Autoportante - Simple",
    "ceiling_autoport_double": "Plafoane Autoportante - Duble",
    "ceiling_autoport_triple": "Plafoane Autoportante - Triple",
    "ceiling_autoport_quadruple": "Plafoane Autoportante - Cvadruple",
    "walls_smart_simple": "Pereti Smart - Simplu Placat",
    "walls_smart_double": "Pereti Smart - Dublu Placat",
    "minute": "minute",
    "not_a_number": "Valoarea introdusa trebuie sa fie un numar",
    "support_type_brida": "Brida",
    "support_type_tirant": "Tirant",
    "support_type_nonius": "Nonius",
    "support_type_tija": "Tija M8",
    "support_type_racord_lemn": "Racord Lemn",
    "support_type_brida_ac": "Brida AC",
    "support_type_beton": "Beton",
    "support_type_zidarie": "Zidarie",
    "support_type_tencuieli": "Tencuieli",
    "support_type_altele": "Altele",
    "Da": "Da",
    "Nu": "Nu",
    "support_type_autoportant": "Autoportant",
    "bride": "bride",
    "cu_rezistenta": "cu rezistenta",
    "walls_smart": "Pereti Smart",
    "linings_smart": "Placari Smart",
    "ceilings_smart": "Plafoane Smart",
    "create_offer_plating_smart_fix_simple": "Creaza Oferta - Placari Smart cu fixari - Simple",
    "create_offer_plating_smart_fix_double": "Creaza Oferta - Placari Smart cu fixari - Duble",
    "plating_smart_fix_simple": "Placari Smart cu fixari - Simple",
    "plating_smart_fix_double": "Placari Smart cu fixari - Duble",
    "create_offer_plating_smart_indep_simple": "Creaza Oferta - Placari Smart independente - Simple",
    "create_offer_plating_smart_indep_double": "Creaza Oferta - Placari Smart independente - Duble",
    "plating_smart_indep_simple": "Placari Smart independente - Simple",
    "plating_smart_indep_double": "Placari Smart independente - Duble",
    "create_offer_ceiling_smart_suspended_simple": "Creare oferta - Plafoane Smart Suspendate - Simple",
    "create_offer_ceiling_smart_suspended_double": "Creare oferta - Plafoane Smart Suspendate - Duble",
    "ceiling_smart_suspended_simple": "Plafoane Smart Suspendate - Simple",
    "ceiling_smart_suspended_double": "Plafoane Smart Suspendate - Duble",
    "create_offer_ceiling_smart_autoport_simple": "Creare oferta - Plafoane Smart Autoportante - Simple",
    "create_offer_ceiling_smart_autoport_double": "Creare oferta - Plafoane Smart Autoportante - Duble",
    "ceiling_smart_autoport_simple": "Plafoane Smart Autoportante - Simple",
    "ceiling_smart_autoport_double": "Plafoane Smart Autoportante - Duble",
    "create_offer_plating_smart_sticking": "Creare oferta - Placari Smart Lipire",
    "plating_smart_sticking": "Placari Smart Lipire",
    "unit_mm": "mm",
    "unit_cm": "cm",
    "unit_m": "m",
    "unit_mp": "m2",
    "unit_kg": "kg",
    "unit_kg_per_mp": "Kg/mp",
    "unit_db": "dB",
    "unit_min": "min",
    "unit_max": "max",
    "select": "Selecteaza",
    "download_selection": "Descarca selectia",
    "404": "Ne pare rau, dar pagina nu este disponibila"
};
