export const gr = {
    "login": "ggg_Autentificare",
    "register": "ggg_Inregistreaza un cont nou",
    "change_password": "ggg_Schimbati parola",
    "copyright": "ggg_Copyright",
    "copyright_value": "ggg_Siniat SEE",
    "email_address": "ggg_Adresa de email",
    "address": "ggg_Adresa",
    "locality": "ggg_Localitatea",
    "language": "ggg_Limba",
    "state": "ggg_Judetul",
    "password": "ggg_Parola",
    "forgot_password": "ggg_Ai uitat parola?",
    "enter_account": "ggg_Intra in cont",
    "not_registered": "ggg_Inregistreaza cont nou",
    "walls": "ggg_Pereti",
    "linnings": "ggg_Placari",
    "linnings_f": "ggg_Placari cu fixari",
    "linnings_i": "ggg_Placari independente",
    "linnings_l": "ggg_Placari liniare",
    "linnings_p": "ggg_Placari lipire",
    "linnings_nf": "ggg_Placari Noisy cu fixari",
    "linnings_ni": "ggg_Placari Noisy independente",
    "linnings_nuu": "ggg_Placari Noisy UU",
    "ceilings": "ggg_Plafoane",
    "ceilings_s": "ggg_Plafoane suspendate",
    "ceilings_ss": "ggg_Plafoane autoportante",
    "home": "ggg_Acasa",
    "print": "ggg_Printeaza",
    "personal_information": "ggg_Informatii personale",
    "first_name": "ggg_Prenume",
    "last_name": "ggg_Nume",
    "last_login": "ggg_Ultima logare",
    "logins_number": "ggg_Numar autentificari",
    "phone_number": "ggg_Numar de telefon",
    "status_active": "ggg_Activ",
    "is_admin": "ggg_Admin",
    "created_on": "ggg_Data inregistrare",
    "account_data": "ggg_Datele contului",
    "confirm_password": "ggg_Confirma parola",
    "read_agree": "ggg_Sunt de acord cu ",
    "terms_conditions": "ggg_termene si conditii",
    "finish_register": "ggg_Inregistreaza contul",
    "already_registered": "ggg_Esti deja inregistrat? Autentifica-te",
    "required_field": "ggg_Acest camp este obligatoriu",
    "minimum_characters": "ggg_Numar minim de caractere este __min__",
    "maximum_characters": "ggg_Numar maxim de caractere este __max__ ",
    "valid_email": "ggg_Adresa de email nu este valida",
    "invalid_phone_format": "ggg_Format invalid",
    "reset_password": "ggg_Resetare Parola",
    "reset_password_button": "ggg_Recupereaza parola",
    "back_to_login": "ggg_Inapoi la autentificare",
    "page_loading": "ggg_Pagina se incarca, va rugam asteptati",
    "new_password": "ggg_Parola noua",
    "confirm_new_password": "ggg_Confirma parola noua",
    "save_password": "ggg_Salveaza parola",
    "successfully_registered": "ggg_Inregistrare cu succes",
    "successfully_registered_paragraph": "ggg_Inregistrarea contului a fost efectuata cu succes",
    "successfully_registered_approval": "ggg_Contul dumnevoastra va fi activat in cel mai scurt timp",
    "resend_confirmation": "ggg_Retrimite email-ul de confirmare",
    "account_not_activated": "ggg_Contul nu este activat",
    "account_activated": "ggg_Contul a fost activat",
    "account_activated_description": "ggg_Contul a fost activat cu succes",
    "resend_confirm_account_description_1": "ggg_Completati adresa de email cu care v-ati inregistrati",
    "resend_confirm_account_description_2": "ggg_Odata ce primesti email, apasati pe butonul de Confirmare cont pentru a activa contul",
    "agree_terms_conditions": "ggg_Trebuie sa acceptati termenele si conditiile pentru a continua inregistrarea",
    "use_new_password": "ggg_Parola s-a resetat. Foloseste noua ta parola pentru autentificare",
    "not_activated": "ggg_Nu ati primit email de activare cont? Puteti solicita unul nou aici",
    "resend_confirmation_success": "ggg_Daca contul a fost inregistrat cu aceasta adresa de mail, ar trebui sa primesti un link de confirmare. Te rog sa verifici se sectiunea de spam",
    "successfully_requested_reset_password": "ggg_Cererea de resetare a parolei a fost trimisa via email. Te rugam verifica inclusiv in folderul spam",
    "saved_password": "ggg_Parola a fost salvata. O sa fi redirectionat intr-un timp scurt",
    "january": "ggg_ianuarie",
    "february": "ggg_februarie",
    "march": "ggg_martie",
    "april": "ggg_aprilie",
    "may": "ggg_mai",
    "june": "ggg_iunie",
    "july": "ggg_iulie",
    "august": "ggg_august",
    "september": "ggg_septembrie",
    "october": "ggg_octombrie",
    "november": "ggg_noiembrie",
    "december": "ggg_decembrie",
    "logout": "ggg_Iesire",
    "list_empty": "ggg_Aceasta lista este goala",
    "attach_image": "ggg_Incarca imaginea",
    "file_too_large": "ggg_Acest fisier este prea mare",
    "file_wrong_file_type_spreadsheet": "ggg_Tipul de fisier nu este suportat. Va rugam sa folositi un fisier de tip .xls, .xlsx",
    "numbers_only": "ggg_Numbers only",
    "save": "ggg_Save",
    "back": "ggg_Inapoi",
    "actions": "ggg_Operatii",
    "confirm": "ggg_Confirma",
    "cancel": "ggg_Anuleaza",
    "close": "ggg_Inchide",
    "add_offer": "ggg_Adauga Oferta",
    "manage_offers": "ggg_Ofertele mele",
    "height": "ggg_Inaltime",
    "profile_type": "ggg_Tip profil",
    "fire_resistance": "ggg_Rezistenta la foc",
    "moisture_resistance": "ggg_Rezistenta la umiditate",
    "sound_insulation": "ggg_Vata minerala",
    "sound_insulation_value": "ggg_Izolare acustica (dB)",
    "sound_proof": "ggg_Izolare fonica",
    "back_to_offers": "ggg_Inapoi la oferte",
    "support": "ggg_Tip suport",
    "finishing": "ggg_Finisare",
    "imported_file": "ggg_Fisierul a fost prelucrat cu succes",
    "interax": "ggg_Interax",
    "price": "ggg_Pret (RON)",
    "sap_code": "ggg_Cod SAP",
    "product_name": "ggg_Nume produs",
    "amount": "ggg_Cantitate",
    "square_meter": "ggg_mp",
    "unit_of_measure": "ggg_UM",
    "project_info": "ggg_Informatii Proiect",
    "joint_length": "ggg_Lungime rost",
    "boq_code": "ggg_Denumire BoQ",
    "system_name": "ggg_Denumire sistem",
    "offer_details": "ggg_Detaliile ofertei",
    "compatible_offers": "ggg_Oferte compatibile criteriilor tale",
    "choose_system": "ggg_Alege tipul de sistem",
    "face1Plate1": "ggg_Fata1: Placa1",
    "face1Plate2": "ggg_Fata1: Placa2",
    "face1Plate3": "ggg_Fata1: Placa3",
    "face1Plate4": "ggg_Fata1: Placa4",
    "face2Plate1": "ggg_Fata2: Placa1",
    "face2Plate2": "ggg_Fata2: Placa2",
    "face2Plate3": "ggg_Fata2: Placa3",
    "face2Plate4": "ggg_Fata2: Placa4",
    "more_details": "ggg_Mai multe detalii aici",
    "save_offer": "ggg_Salveaza oferta",
    "interax_sustineri": "ggg_Interax Sustineri",
    "consumuri": "ggg_Necesar de materiale",
    "prindere_superioara": "ggg_Necesar de materiale prindere superioara",
    "surface": "ggg_Suprafata",
    "delete_offer_title": "ggg_Esti sigur ca doresti sa stergi aceasta oferta?",
    "delete_offer_description": "ggg_Nu o sa mai aveti oferta salvata dupa ce o stergeti",
    "deleted_offer": "ggg_Oferta stearsa",
    "delete_session_title": "ggg_Esti sigur ca doresti sa stergi acest proiect?",
    "delete_session_description": "ggg_Nu o sa mai aveti proiectul salvat dupa ce o stergeti",
    "company": "ggg_Organizatie",
    "company_register": "ggg_Numele firmei pe care o reprezentati",
    "project_code": "ggg_Cod proiect Siniat",
    "job": "ggg_Obiectul de activitate",
    "contact_person": "ggg_Persoana de contact",
    "email": "ggg_Email",
    "objective": "ggg_Denumire Obiectiv",
    "type_objective": "ggg_Tip Obiectiv",
    "type_objective_option_1": "ggg_Bloc locuinte",
    "type_objective_option_2": "ggg_Cladire birouri",
    "type_objective_option_3": "ggg_Hala industriala sau depozit",
    "type_objective_option_4": "ggg_Complex comercial",
    "type_objective_option_5": "ggg_Hotel",
    "type_objective_option_6": "ggg_Spital",
    "type_objective_option_7": "ggg_Divertisment(piscina, cinema, sala concrete etc.)",
    "type_objective_option_8": "ggg_Invatamant",
    "location": "ggg_Judet",
    "description": "ggg_Descriere",
    "validation_date": "ggg_Valid pana la data de:",
    "nr": "ggg_Nr crt",
    "delete_session": "ggg_Sterge proiectul",
    "download_session": "ggg_Descarca proiectul",
    "view_session_offers": "ggg_Vizualizeaza ofertele proiectului",
    "edit_session": "ggg_Editeaza proiectul",
    "create_new_session": "ggg_Creeaza un proiect nou",
    "new_offer": "ggg_Creaza oferta noua",
    "edit_offer_plates": "ggg_Editeaza placile ofertei",
    "finish_edit_plates": "ggg_Termina editarea placilor ofertei",
    "import_file": "ggg_Prelucreaza fisier",
    "choose_import": "ggg_Alege tipul de fisier de calcul",
    "imports": "ggg_Import fisiere de calcul",
    "burglary_resistance": "ggg_Rezistenta la efractie",
    "number_of_offers": "ggg_Numarul de oferte create",
    "date_created": "ggg_Data crearii ofertei",
    "back_to_create_offer": "ggg_Inapoi la generarea de oferta",
    "generate_offers": "ggg_Genereaza oferte compatibile",
    "reset_offer": "ggg_Reseteaza campurile ofertei",
    "my_sessions": "ggg_Proiectele mele",
    "manage_users": "ggg_Administrare utilizatori",
    "reports": "ggg_Rapoarte",
    "sessions_offers": "ggg_Proiecte / Oferte",
    "save_session": "ggg_Salveaza proiectul",
    "download": "ggg_Descarca",
    "download_file": "ggg_Descarca fisierul",
    "download_users": "ggg_Descarca lista",
    "next": "ggg_Continua",
    "walls_s": "ggg_Simplu Placat",
    "walls_d": "ggg_Dublu Placat",
    "walls_t": "ggg_Triplu Placat",
    "linnings_s": "ggg_Placari Simple",
    "linnings_d": "ggg_Placari Duble",
    "linnings_t": "ggg_Placari Triple",
    "linnings_q": "ggg_Placari Cvadruple",
    "linnings_ei": "Placari EI180 min (5 placi)",
    "ceilings_plates_s": "ggg_Plafoane Simple",
    "ceilings_plates_d": "ggg_Plafoane Duble",
    "ceilings_plates_t": "ggg_Plafoane Triple",
    "ceilings_plates_q": "ggg_Plafoane Cvadruple",
    "date_validation": "ggg_Data de validare",
    "face": "ggg_fata",
    "exterior": "ggg_exterioara",
    "and": "ggg_si",
    "sistem_weight": "ggg_Greutate Sistem",
    "montant_type": "ggg_Tip montant",
    "upper_guiding": "ggg_Tip ghidaj superior",
    "lower_guiding": "ggg_Tip ghidaj inferior",
    "plating_types": "ggg_Tip placi",
    "plating_type_face_a": "ggg_Tip placi Fata A",
    "plating_type_face_b": "ggg_Tip placi Fata B",
    "upper_support": "ggg_Tip suport superior",
    "wool_type": "ggg_Tip izolatie",
    "thickness_system": "ggg_Grosime Sistem",
    "ceilings_distance": "ggg_Distanta pana la planseu",
    "ceilings_support": "ggg_Tip de sustinere",
    "system_code": "ggg_Cod Sistem",
    "protection_direction": "ggg_Sens Protectie",
    "sound_insulation_label": "ggg_Izolare Acustica",
    "plating_type_lower_face": "ggg_Tip placi fata inferioara",
    "plating_type_upper_face": "ggg_Tip placi fata superioara",
    "main_structure": "ggg_Tip structura principala",
    "secondary_structure": "ggg_Tip structura secundara",
    "parameter_structure": "ggg_Tip ghidaj perimetral",
    "interax_secondary": "ggg_Interax structura secundara",
    "type_intermediate_plate": "ggg_Tip placa intermediara",
    "distance": "ggg_Distanta dintre structuri",
    "structure_type": "ggg_Tip Sustineri",
    "manage_account": "ggg_Contul meu",
    "edit_account": "ggg_Editeaza datele contul",
    "finish_edit_account": "ggg_Salveaza datele contului",
    "edit_password": "ggg_Editeaza Parola",
    "edit_password_confirm": "ggg_Editeaza parola utilizatorului",
    "edit_password_cancel": "ggg_Anuleaza editarea parolei",
    "based_plates": "ggg_Placa de baza a sistemului",
    "walls_s_asimetric": "ggg_Asimetrici",
    "walls_s_intermediar_d": "ggg_Dublu Intermediar",
    "walls_s_intermediar_t": "ggg_Triplu Intermediar",
    "intermediate_plate": "ggg_Placa Intermediara",
    "too_much": "ggg_Valoarea este prea mare",
    "max_opening": "ggg_Deschidere maxima",
    "price_per_unit": "ggg_Pret(RON) / mp",
    "rows_per_page": "ggg_Randuri pe pagina: ",
    "no_range": "ggg_Nu respecta intervalul!",
    "no_offers": "ggg_Momentan nu exista nici o oferta creata",
    "passwords_must_match": "ggg_Parola nu este aceeasi! Va rugam sa o introduceti din nou",
    "description_popup": "ggg_Aici regasiti primele 6 cele mai ieftine sisteme configurate conform cerinteleor dumneavoastra. Pe coloane puteti urmari o mare parte dintre particularitatile fiecarui sistem, pentru a putea selecta sistemul dorit. Apasati OK pentru a trece mai departe",
    "search": "ggg_Cauta",
    "less_table_cols": "ggg_Compacteaza tabelul",
    "report": "ggg_Raport",
    "import": "ggg_Import",
    "materials": "ggg_Consumuri",
    "allowed_plates": "ggg_Placi Permise",
    "products": "ggg_Produse",
    "ceilings_plates_s_tooltip": "ggg_Plafoane cu o placa",
    "ceilings_plates_d_tooltip": "ggg_Plafoane cu doua placi",
    "ceilings_plates_t_tooltip": "ggg_Plafoane cu trei placi",
    "ceilings_plates_q_tooltip": "ggg_Plafoane cu patru placi",
    "separative": "ggg_Separativi",
    "linnings_noisy_double": "ggg_Placari Noisy Duble",
    "linnings_noisy_triple": "ggg_Placari Noisy Triple",
    "price_list": "ggg_Lista de preturi",
    "attach_file": "ggg_Incarcati fisierul",
    "attach_file_for": "ggg_Incarcati fisierul pentru importul de",
    "attach_file_last_timestamp": "ggg_Ultima actualizare pentru __file__ a fost la",
    "attach_file_last_file": "ggg_Ultimul fisier incarcat",
    "thickness": "ggg_Grosime",
    "choose_project": "ggg_Alege un proiect pentru aceasta oferta",
    "create_new_project": "ggg_Creeaza proiect nou",
    "quit": "ggg_Renunta",
    "select_project": "ggg_Selecteaza proiectul",
    "unspecified": "ggg_nespecificat",
    "systemType": "ggg_Tip sistem",
    "PDF_details": "ggg_Detalii PDF",
    "DWG_details": "ggg_Detalii DWG",
    "brochures_sistems": "ggg_Brosura sisteme",
	'systemCode': 'ggg_Cod sistem',
	'technical_characteristics': 'ggg_Caracteristici Tehnice',
	'value': 'ggg_Valoare',
	'more_details_here': 'ggg_Mai multe detalii aici',
	'create_offer_walls_d_simple': "ggg_Creare oferta - Pereti D - Simplu Placat",
	'create_offer_walls_d_double': "ggg_Creare oferta - Pereti D - Dublu Placat",
	'create_offer_walls_d_triple': "ggg_Creare oferta - Pereti D - Triplu Placat",
	'create_offer_walls_s_double': "ggg_Creare oferta - Pereti S - Dublu Placat",
	'create_offer_walls_s_triple': "ggg_Creare oferta - Pereti S - Triplu Placat",
	'create_offer_walls_s_asimetric': "ggg_Creare oferta - Pereti S - Asimetrici",
	'create_offer_walls_s_double_intermediate': "ggg_Creare oferta - Pereti S - Dublu Intermediar",
	'create_offer_walls_s_triple_intermediate': "ggg_Creare oferta - Pereti S - Triplu Intermediar",
	'create_offer_walls_sl_simple': "ggg_Creare oferta - Pereti SL - Simplu Placat",
	'create_offer_walls_sl_double': "ggg_Creare oferta - Pereti SL - Dublu Placat",
	'create_offer_walls_sl_triple': "ggg_Creare oferta - Pereti SL - Triplu Placat",
	'create_offer_walls_sla_triple': "ggg_Creare oferta - Pereti SLA - Triplu Placat",
	'create_offer_plating_fix_simple': "ggg_Creare oferta - Placari cu fixari - Simple",
	'create_offer_plating_fix_double': "ggg_Creare oferta - Placari cu fixari - Duble",
	'create_offer_plating_fix_triple': "ggg_Creare oferta - Placari cu fixari - Triple",
	'create_offer_plating_fix_quadruple': "ggg_Creare oferta - Placari cu fixari - Cvadruple",
    'create_offer_plating_indep_simple': "ggg_Creare oferta - Placari independente - Simple",
	'create_offer_plating_indep_double': "ggg_Creare oferta - Placari independente - Duble",
	'create_offer_plating_indep_triple': "ggg_Creare oferta - Placari independente - Triple",
	'create_offer_plating_indep_quadruple': "ggg_Creare oferta - Placari independente - Cvadruple",
    'plating_indep_ei': "Placari independente - EI180 min (5 placi)",
    'plating_fix_ei': "Placari cu fixari - EI180 min (5 placi)",
    'create_offer_plating_indep_ei': "Creare oferta - Placari independente - EI180 min (5 placi)",
    'create_offer_plating_liniar_double': "ggg_Creare oferta - Placari liniare - Duble",
	'create_offer_plating_liniar_triple': "ggg_Creare oferta - Placari liniare - Triple",
	'create_offer_plating_liniar_quadruple': "ggg_Creare oferta - Placari liniare - Cvadruple",
	'create_offer_plating_sticking': "ggg_Creare oferta - Placari Lipire",
    'create_offer_noisy_plating_fix_triple': "ggg_Creare oferta - Placari Noisy cu fixari - Triple",
    'create_offer_noisy_plating_indep_double': "ggg_Creare oferta - Placari Noisy independente - Duble",
    'create_offer_noisy_plating_indep_triple': "ggg_Creare oferta - Placari Noisy independente - Triple",
    'create_offer_noisy_plating_uu_double': "ggg_Creare oferta - Placari Noisy UU - Duble",
    'create_offer_noisy_plating_uu_triple': "ggg_Creare oferta - Placari Noisy UU - Triple",
	'create_offer_ceiling_suspended_simple': "ggg_Creare oferta - Plafoane Suspendate - Simple",
	'create_offer_ceiling_suspended_double': "ggg_Creare oferta - Plafoane Suspendate - Duble",
	'create_offer_ceiling_suspended_triple': "ggg_Creare oferta - Plafoane Suspendate - Triple",
	'create_offer_ceiling_suspended_quadruple': "ggg_Creare oferta - Plafoane Suspendate - Cvadruple",
	'create_offer_ceiling_autoport_simple': "ggg_Creare oferta - Plafoane Autoportante - Simple",
	'create_offer_ceiling_autoport_double': "ggg_Creare oferta - Plafoane Autoportante - Duble",
	'create_offer_ceiling_autoport_triple': "ggg_Creare oferta - Plafoane Autoportante - Triple",
	'create_offer_ceiling_autoport_quadruple': "ggg_Creare oferta - Plafoane Autoportante - Cvadruple",
    "download_offer": "ggg_Descarca Oferta",
    "delete_offer" : "ggg_Sterge Oferta",
    "compatible_offers:" : "ggg_Oferte Compatibile",
    "country" : "ggg_Tara",
};
