export const cr = {
    login: "Prijavite se",
    register: "Izradite novi račun",
    change_password: "Promijenite lozinku",
    copyright: "Autorsko pravo",
    copyright_value: "Siniat SEE",
    email_address: "e-mail",
    address: "Adresa",
    locality: "Grad",
    language: "Jezik",
    state: "Država",
    password: "Lozinka",
    forgot_password: "Zaboravili ste lozinku?",
    enter_account: "Prijava",
    not_registered: "Izradite novi račun",
    walls: "Zidovi",
    linnings: "Obloge",
    linnings_f: "Obloge s konektorima",
    linnings_i: "Samostojeće obloge",
    linnings_l: "Šahte",
    linnings_p: "Obloga lijepljenjem",
    linnings_nf: "Akustična obloga s konektorima",
    linnings_ni: "Samostojeća akustična obloga",
    linnings_nuu: "Akustična UU obloga",
    ceilings: "Stropovi",
    ceilings_s: "Spušteni stropovi",
    ceilings_ss: "Samonosivi stropovi",
    home: "Početna",
    print: "Ispis",
    personal_information: "Osobne informacije",
    first_name: "Ime",
    last_name: "Prezime",
    last_login: "Zadnja prijava",
    logins_number: "Broj prijave",
    phone_number: "Broj telefona",
    status_active: "Aktivan",
    is_admin: "Admin",
    created_on: "Datum registracije",
    account_data: "Informacije o računu",
    confirm_password: "Potvrdite lozinku",
    read_agree: "Prihvaćam",
    terms_conditions: "uvjete i odredbe",
    finish_register: "Registrirajte svoj račun",
    already_registered: "Jeste li već registrirani? Prijavite se",
    required_field: "Ovo polje je obavezno",
    minimum_characters: "Minimalni broj znakova je __min__",
    maximum_characters: "Maksimalni broj znakova je __max__",
    valid_email: "Nevažeća email adresa",
    invalid_phone_format: "Nevažeći format",
    reset_password: "Resetirajte lozinku",
    reset_password_button: "Oporavite lozinku",
    back_to_login: "Natrag na prijavu",
    page_loading: "Stranica se učitava, pričekajte",
    new_password: "Nova lozinka",
    confirm_new_password: "Potvrdite novu lozinku",
    save_password: "Spremite lozinku",
    successfully_registered: "Uspješna registracija",
    successfully_registered_paragraph: "Registracija računa bila je uspješna",
    successfully_registered_approval:
      "Vaš račun će biti aktiviran što je prije moguće",
    resend_confirmation: "Ponovno pošalji e-poruku za potvrdu",
    account_not_activated: "Vaš račun nije aktiviran",
    account_activated: "Vaš račun je aktiviran",
    account_activated_description: "Vaš račun je uspješno aktiviran",
    resend_confirm_account_description_1:
      "Ispunite email adresu koju ste koristili za registraciju",
    resend_confirm_account_description_2:
      "Nakon što primite e-poštu, kliknite na gumb Potvrdi račun kako biste aktivirali svoj račun",
    agree_terms_conditions:
      "Morate prihvatiti uvjete i odredbe da biste nastavili registraciju",
    use_new_password:
      "Lozinka je poništena. Koristite svoju novu lozinku za prijavu",
    not_activated:
      "Niste primili e-poštu za aktivaciju računa? Ovdje možete zatražiti novu",
    resend_confirmation_success:
      "Ako je vaš račun registriran s ovom adresom e-pošte, trebali biste dobiti poveznicu za potvrdu. Molimo provjerite odjeljak za neželjenu poštu",
    successfully_requested_reset_password:
      "Zahtjev za poništenjem lozinke poslan je e-poštom. Provjerite svoju mapu neželjene pošte",
    saved_password: "Vaša lozinka je spremljena. Uskoro ćete biti preusmjereni",
    january: "siječanj",
    february: "veljača",
    march: "ožujak",
    april: "travanj",
    may: "svibanj",
    june: "lipanj",
    july: "srpanj",
    august: "kolovoz",
    september: "rujan",
    october: "listopad",
    november: "studeni",
    december: "prosinac",
    logout: "Izađite",
    list_empty: "Ovaj popis je prazan",
    attach_image: "Učitajte sliku",
    file_too_large: "Ova datoteka je prevelika",
    file_wrong_file_type_spreadsheet: "Ova vrsta datoteke nije podržana. Molimo koristite .xls ili .xlsx datoteke",
    numbers_only: "Samo brojevi",
    save: "Sačuvati",
    back: "Povratak",
    actions: "Operacije",
    confirm: "Potvrdite",
    cancel: "Otkazati",
    close: "Zatvoriti",
    add_offer: "Dodajte ponudu",
    manage_offers: "Moje ponude",
    height: "Visina",
    profile_type: "Vrsta profila",
    fire_resistance: "Otpornost na vatru",
    moisture_resistance: "Otpornost na vlagu",
    sound_insulation: "Mineralna vuna",
    sound_insulation_value: "Zvučna izolacija (dB)",
    sound_proof: "Zvučna izolacija",
    back_to_offers: "Povratak na ponude",
    support: "Vrsta podloge",
    finishing: "Završna obrada",
    imported_file: "Datoteka je uspješno obrađena",
    interax: "Razmak",
    price: "Cijena (EURO)",
    sap_code: "SAP kod",
    product_name: "Ime proizvoda",
    amount: "Količina",
    square_meter: "m2",
    unit_of_measure: "JM",
    project_info: "Informacije o projektu",
    joint_length: "Duljina spoja",
    boq_code: "BoQ naziv",
    system_name: "Naziv sustava",
    offer_details: "Ponudi detalje",
    compatible_offers: "Ponude koje zadovoljavaju vaše kriterije",
    choose_system: "Odaberite vrstu sustava",
    face1Plate1: "Strana 1: Gipskartonska ploča 1",
    face1Plate2: "Strana 1: Gipskartonska ploča 2",
    face1Plate3: "Strana 1: Gipskartonska ploča 3",
    face1Plate4: "Strana 1: Gipskartonska ploča 4",
    face1Plate5: "Strana 1: Gipskartonska ploča 5",
    face2Plate1: "Strana 2: Gipskartonska ploča 1",
    face2Plate2: "Strana 2: Gipskartonska ploča 2",
    face2Plate3: "Strana 2: Gipskartonska ploča 3",
    face2Plate4: "Strana 2: Gipskartonska ploča 4",
    more_details: "Više detalja ovdje",
    save_offer: "Spremi ponudu",
    interax_sustineri: "Međuosni razmak potkonstrukcije",
    consumuri: "Potrebni materijali",
    prindere_superioara: "Potrebni materijali za gornje pričvršćenje",
    surface: "Površina",
    delete_offer_title: "Jeste li sigurni da želite izbrisati ove ponude?",
    delete_offer_description:
      "Nakon što ih izbrišete, vaše ponude više neće biti sačuvane",
    deleted_offer: "Izbrisana ponuda",
    delete_session_title: "Jeste li sigurni da želite izbrisati ovaj projekt?",
    delete_session_description:
      "Nakon što ga izbrišete, vaš projekt više neće biti sačuvan",
    company: "Organizacija",
    company_register: "Naziv tvrtke koju zastupate",
    project_code: "Šifra projekta Siniat",
    job: "Područje djelatnosti",
    contact_person: "Kontakt osoba",
    email: "E-mail",
    objective: "Naziv građevinskog projekta",
    type_objective: "Vrsta građevinskog projekta",
    type_objective_option_1: "Stambena građevina",
    type_objective_option_2: "Poslovna građevina",
    type_objective_option_3: "Industrijska zgrada ili skladište",
    type_objective_option_4: "Trgovački centar",
    type_objective_option_5: "Hotel",
    type_objective_option_6: "Bolnica",
    type_objective_option_7: "Zabava (bazen, kino, koncertna dvorana, itd.)",
    type_objective_option_8: "Obrazovanje",
    location: "Regija",
    description: "Opis",
    validation_date: "Vrijedi do:",
    nr: "Ref. br.",
    delete_session: "Izbrišite projekt",
    download_session: "Preuzmite projekt",
    view_session_offers: "Pogledajte ponude za projekt",
    edit_session: "Uredite projekt",
    create_new_session: "Kreirajte novi projekt",
    new_offer: "Kreirajte novu ponudu",
    edit_offer_plates: "Uređivanje gipskartonskih ploča uključenih u ponudu",
    finish_edit_plates:
      "Završite uređivanje gipskartonskih ploča uključenih u ponudu",
    import_file: "Obradite datoteku",
    choose_import: "Odaberite vrstu datoteke za izračun",
    imports: "Uvezite datoteke za izračun",
    burglary_resistance: "Otpornost na provalu",
    number_of_offers: "Broj kreiranih ponuda",
    date_created: "Datum kreiranja ponude",
    back_to_create_offer: "Natrag na generiranje ponuda",
    generate_offers: "Generirajte kompatibilne ponude",
    reset_offer: "Poništite polja ponude",
    my_sessions: "Moji projekti",
    manage_users: "Upravljanje korisnicima",
    reports: "Izvještaji",
    sessions_offers: "Projekti/Ponude",
    save_session: "Spremite projekt",
    download: "Preuzmite",
    download_file: "Preuzmite datoteku",
    download_users: "Popis preuzimanja",
    next: "Nastaviti",
    walls_s: "Jednostruka obloga",
    walls_d: "Dvostruka obloga",
    walls_t: "Trostruka obloga",
    linnings_s: "Jednostruka obloga",
    linnings_d: "Dvostruka obloga",
    linnings_t: "Trostruka obloga",
    linnings_q: "Četverostruka obloga",
    linnings_ei: "Obloga - EI180 min (5 gipskartonska ploča)",
    ceilings_plates_s: "Jednostruka obloga",
    ceilings_plates_d: "Dvostruka obloga",
    ceilings_plates_t: "Trostruka obloga",
    ceilings_plates_q: "Četverostruka obloga",
    date_validation: "Datum provjere",
    face: "strana",
    exterior: "vanjski",
    and: "i",
    sistem_weight: "Težina sustava",
    montant_type: "Vrsta profila",
    upper_guiding: "Vrsta profila, vrh",
    lower_guiding: "Vrsta profila, dno",
    plating_types: "Vrsta gipskartonske ploče",
    plating_type_face_a: "Vrsta gipskartonske ploče, strana A",
    plating_type_face_b: "Vrsta gipskartonske ploče, strana B",
    upper_support: "Vrsta profila, vrh",
    wool_type: "Vrsta izolacije",
    thickness_system: "Debljina sustava",
    ceilings_distance: "Duljina spuštanja stropa",
    ceilings_support: "Vrsta ovjesa",
    system_code: "Šifra sustava",
    protection_direction: "Smjer zaštite",
    sound_insulation_label: "Zvučna izolacija",
    plating_type_lower_face: "Vrsta gipskartonske ploče, donja strana",
    plating_type_upper_face: "Vrsta gipskartonske ploče, gornja strana",
    main_structure: "Vrsta glavne potkonstrukcije",
    secondary_structure: "Sekundarna potkonstrukcija",
    parameter_structure: "Vrsta profila",
    interax_secondary: "Razmak sekundarne potkonstrukcija",
    type_intermediate_plate: "Vrsta srednje gipsane ploče",
    distance: "Udaljenost između potkonstrukcije",
    structure_type: "Vrsta nosača",
    manage_account: "Moj račun",
    edit_account: "Uredite podatke o računu",
    finish_edit_account: "Spremite podatke o računu",
    edit_password: "Uredite lozinku",
    edit_password_confirm: "Uredite korisničku lozinku",
    edit_password_cancel: "Otkažite uređivanje lozinke",
    based_plates: "Glavna vrsta ploča",
    walls_s_asimetric: "Zidovi S - Asimetrični",
    walls_s_intermediar_d: "Dvostruki sa srednjom pločom",
    walls_s_intermediar_t: "Trostruki sa srednjom pločom",
    intermediate_plate: "Srednja gipskartonska ploča",
    too_little: "Ova vrijednost je preniska",
    too_much: "Ova vrijednost je previsoka",
    max_opening: "Maks. otvor",
    price_per_unit: "Cijena (EURO)/m2",
    rows_per_page: "Redova po stranici:",
    no_range: "Izvan intervala!",
    no_offers: "Trenutno nema ponude u izradi",
    passwords_must_match: "Lozinka je drugačija! Molimo unesite ju ponovno",
    description_popup:
      "Ovdje je 6 najekonomičnijih sustava konfiguriranih prema vašim zahtjevima. U stupcima možete pronaći mnoge značajke svakog sustava, tako da možete odabrati onaj koji želite. Kliknite OK za nastavak",
    search: "Pretražite",
    less_table_cols: "Sažmi tablicu",
    report: "Izvješće",
    import: "Uvoz",
    materials: "Potrošnja",
    allowed_plates: "Dozvoljene ploče",
    products: "Proizvodi",
    ceilings_plates_s_tooltip: "Jednoslojni stropovi",
    ceilings_plates_d_tooltip: "Dvoslojni stropovi",
    ceilings_plates_t_tooltip: "Troslojni stropovi",
    ceilings_plates_q_tooltip: "Četveroslojni stropovi",
    separative: "Razdvojni tip",
    linnings_noisy_double: "Dvostruka akustična obloga",
    linnings_noisy_triple: "Trostruka akustična podstava",
    price_list: "Cjenik",
    attach_file: "Prenesite datoteku",
    attach_file_for: "Prenesite datoteku za uvoz",
    attach_file_by: "Priložena datoteka od",
    attach_file_last_timestamp: "Posljednje ažuriranje za __file__ dogodilo se u",
    attach_file_last_file: "Posljednja prenesena datoteka",
    thickness: "Debljina",
    choose_project: "Odaberite projekt za ovu ponudu",
    create_new_project: "Kreirajte novi projekt",
    quit: "Izlaz",
    unspecified: "Neodređeno",
    product: "Izlaz",
    select_project: "Neodređeno",
    systemType: "Vrsta sustava",
    PDF_details: "PDF pojedinosti",
    DWG_details: "DWG Detalji ",
    brochures_sistems: "Brošura za sustave",
    systemCode: "Šifra sustava",
    technical_characteristics: "Tehničke karakteristike",
    value: "Vrijednost",
    more_details_here: "Više detalja ovdje",
    create_offer: "Kreirajte ponudu",
    walls_d_simple: "Zidovi D - Jednostruka obloga",
    walls_d_double: "Zidovi D - Dvostruka obloga",
    walls_d_triple: "Zidovi D - Trostruka obloga",
    walls_s_double: "Zidovi S - Dvostruka obloga",
    walls_s_triple: "Zidovi S - Trostruka obloga",
    walls_s_double_intermediate: "Zidovi S - Dvostruki sa srednjom pločom",
    walls_s_triple_intermediate: "Zidovi S - Trostruki sa srednjom pločom",
    walls_sl_simple: "Zidovi SL - Jednostruka obloga",
    walls_sl_double: "Zidovi SL - Dvostruka obloga",
    walls_sl_triple: "Zidovi SL - Trostruka obloga",
    walls_sla_triple: "Zidovi SLA - Trostruka obloga",
    plating_fix_simple: "Obloga s konektorima - Jednostruka",
    plating_fix_double: "Obloga s konektorima  - Dvostruka",
    plating_fix_triple: "Obloga s konektorima  - Trostruka",
    plating_fix_quadruple: "Obloga s konektorima  - Četverostruka",
    plating_fix_ei: "Obloge s konektorima - EI180 min (5 gipskartonska ploča)",
    plating_indep_simple: "Samostojeća obloga - Jednostruka",
    plating_indep_double: "Samostojeća obloga - Dvostruka",
    plating_indep_triple: "Samostojeća obloga - Trostruka",
    plating_indep_quadruple: "Samostojeća obloga - Četverostruka",
    plating_indep_ei: "Samostojeće obloge - EI180 min (5 gipskartonska ploča)",
    plating_liniar_double: "Šahta - Dvostruka",
    plating_liniar_triple: "Šahta - Trostruka",
    plating_liniar_quadruple: "Šahta - Četverostruka",
    plating_liniar_cv: "Šahte - EI180 min (5 gipskartonska ploča)",
    plating_sticking: "Obloga lijepljenem",
    noisy_plating_fix_triple: "Akustična obloga s konektorima - Trostruka",
    noisy_plating_indep_double: "Samostojeća akustična obloga s konektorima - Dvostruka",
    noisy_plating_indep_triple: "Samostalna akustična obloga s konektorima - Trostruka",
    noisy_plating_uu_double: "Akustična UU obloga - Dvostruka",
    noisy_plating_uu_triple: "Akustična UU obloga - Trostruka",
    ceiling_suspended_simple: "Spušteni stropovi - Jednostruki",
    ceiling_suspended_double: "Spušteni stropovi - Dvostruki",
    ceiling_suspended_triple: "Spušteni stropovi - Trostruki",
    ceiling_suspended_quadruple: "Spušteni stropovi - Četverostruki",
    ceiling_autoport_simple: "Samonosivi stropovi - Jednostruki",
    ceiling_autoport_double: "Samonosivi stropovi - Dvostruki",
    ceiling_autoport_triple: "Samonosivi stropovi - Trostruki",
    ceiling_autoport_quadruple: "Samonosivi stropovi - Četverostruki",
    download_offer: "Preuzmite ponudu",
    delete_offer: "Izbrišite ponudu",
    compatible_offers: "Kompatibilne ponude",
    country: "Zemlja",
    direction_0: "Neodređeno",
    direction_1: "Zaštita s donje strane",
    direction_2: "Zaštita s gornje strane ",
    direction_3: "Zaštita u oba smjera",
    dont_show_message: "Ne prikazuj više ovu poruku",
    principal_structure: "Glavna konstrukcija",
    beam_type: "Uspravno",
    change_county: "Promijenite županije",
    save_county: "Sačuvaj županije",
    county: "Županije",
    access: "Pristup",
    any: "Bilo koji",
    one_face: "Jedna strana",
    one_exterior_face: "Vanjska strana",
    two_faces: "Dvije strane",
    with_mineral_wool: "S mineralnom vunom",
    without_mineral_wool: "Bez mineralne vune",
    without_finishing: "Bez završne obrade",
    with_finishing: "Uz završnu obradu",
    "Planseu beton armat": "Armirani beton",
    "Tabla cutata de acoperis": "Trapezni krovni lim",
    minute: "minutama",
    simple_structure: "jednostruka potkonstrukcija",
    double_structure: "dvostruka potkonstrukcija",
    one_face_cementex: "Jedna strana Cementex",
    without: "Bez",
    vinclu: "Kut",
    continue: "Nastavite",
    Da: "Da",
    "Da, cu 1 strat": "Da, s jednim slojem",
    "Da, cu 2 straturi": "Da, s dva sloja",
    "Da, cu 3 straturi": "Da, s tri sloja",
    "Da, cu 4 straturi": "Da, s četiri sloja",
    Nu: "Ne",
    RON: "EURO",
    Fara: "Bez",
    interaxSustineri: "Međukonektori",
    create_offer_walls_d_simple: "Kreirajte ponudu - Zidovi D - Jednostruka obloga",
    create_offer_walls_d_double: "Kreirajte ponudu - Zidovi D - Dvostruka obloga",
    create_offer_walls_d_triple: "Kreirajte ponudu - Zidovi D - Trostruka obloga",
    create_offer_walls_s_double: "Kreirajte ponudu - Zidovi S - Dvostruka obloga",
    create_offer_walls_s_triple: "Kreirajte ponudu - Zidovi S - Trostruka obloga",
    create_offer_walls_s_asimetric: "Kreirajte ponudu - Zidovi S - Asimetrični",
    create_offer_walls_s_double_intermediate: "Kreirajte ponudu - Zidovi S - Dvostruki sa srednjom pločom",
    create_offer_walls_s_triple_intermediate: "Kreirajte ponudu - Zidovi S - Trostruki sa srednjom pločom",
    create_offer_walls_sl_simple: "Kreirajte ponudu - Zidovi SL - Jednostruka obloga",
    create_offer_walls_sl_double: "Kreirajte ponudu - Zidovi SL - Dvostruka obloga",
    create_offer_walls_sl_triple: "Kreirajte ponudu - Zidovi SL - Trostruka obloga",
    create_offer_walls_sla_triple: "Kreirajte ponudu - Zidovi SLA - Trostruka obloga",
    create_offer_plating_fix_simple: "Kreirajte ponudu - Obloga s konektorima - Jednostruka",
    create_offer_plating_fix_double: "Kreirajte ponudu - Obloga s konektorima  - Dvostruka",
    create_offer_plating_fix_triple: "Kreirajte ponudu - Obloga s konektorima  - Trostruka",
    create_offer_plating_fix_quadruple: "Kreirajte ponudu - Obloga s konektorima  - Četverostruka",
    create_offer_plating_fix_ei: "Kreirajte ponudu - Obloge s konektorima - EI180 min (5 gipskartonska ploča)",
    create_offer_plating_indep_simple: "Kreirajte ponudu - Samostojeća obloga - Jednostruka",
    create_offer_plating_indep_double: "Kreirajte ponudu - Samostojeća obloga - Dvostruka",
    create_offer_plating_indep_triple: "Kreirajte ponudu - Samostojeća obloga - Trostruka",
    create_offer_plating_indep_quadruple: "Kreirajte ponudu - Samostojeća obloga - Četverostruka",
    create_offer_plating_indep_ei: "Kreirajte ponudu - Samostojeće obloge - EI180 min (5 gipskartonska ploča)",
    create_offer_plating_liniar_double: "Kreirajte ponudu - Šahta - Dvostruka",
    create_offer_plating_liniar_triple: "Kreirajte ponudu - Šahta - Trostruka",
    create_offer_plating_liniar_quadruple: "Kreirajte ponudu - Šahta - Četverostruka",
    create_offer_plating_liniar_cv: "Kreirajte ponudu - Šahte - EI180 min (5 gipskartonska ploča)",
    create_offer_plating_sticking: "Kreirajte ponudu - Obloga lijepljenem",
    create_offer_noisy_plating_fix_triple: "Kreirajte ponudu - Akustična obloga s konektorima - Trostruka",
    create_offer_noisy_plating_indep_double: "Kreirajte ponudu - Samostojeća akustična obloga s konektorima - Dvostruka",
    create_offer_noisy_plating_indep_triple: "Kreirajte ponudu - Samostalna akustična obloga s konektorima - Trostruka",
    create_offer_noisy_plating_uu_double: "Kreirajte ponudu - Akustična UU obloga - Dvostruka",
    create_offer_noisy_plating_uu_triple: "Kreirajte ponudu - Akustična UU obloga - Trostruka",
    create_offer_ceiling_suspended_simple: "Kreirajte ponudu - Spušteni stropovi - Jednostruki",
    create_offer_ceiling_suspended_double: "Kreirajte ponudu - Spušteni stropovi - Dvostruki",
    create_offer_ceiling_suspended_triple: "Kreirajte ponudu - Spušteni stropovi - Trostruki",
    create_offer_ceiling_suspended_quadruple: "Kreirajte ponudu - Spušteni stropovi - Četverostruki",
    create_offer_ceiling_autoport_simple: "Kreirajte ponudu - Samonosivi stropovi - Jednostruki",
    create_offer_ceiling_autoport_double: "Kreirajte ponudu - Samonosivi stropovi - Dvostruki",
    create_offer_ceiling_autoport_triple: "Kreirajte ponudu - Samonosivi stropovi - Trostruki",
    create_offer_ceiling_autoport_quadruple: "Kreirajte ponudu - Samonosivi stropovi - Četverostruki",
    create_offer_plating_smart_fix_simple: "Kreirajte ponudu - Obloga Smart s konektorima - Jednostruka",
    create_offer_plating_smart_fix_double: "Kreirajte ponudu - Obloga Smart s konektorima - Dvostruka",
    plating_smart_fix_simple: "Obloga Smart s konektorima - Jednostruka",
    plating_smart_fix_double: "Obloga Smart s konektorima - Dvostruka",
    create_offer_plating_smart_indep_simple: "Kreirajte ponudu - Samostojeća Obloga Smart - Jednostruka",
    create_offer_plating_smart_indep_double: "Kreirajte ponudu - Samostojeća Obloga Smart - Dvostruka",
    plating_smart_indep_simple: "Samostojeća Obloga Smart - Jednostruka",
    plating_smart_indep_double: "Samostojeća Obloga Smart - Dvostruka",
    pereti_smart: "Zidovi Smart",
    nida: "Nida",
    smart: "Smart",
    create_offer_walls_smart_simple : "Kreirajte ponudu - Zidovi Smart - Jednostruka obloga",
    create_offer_walls_smart_double : "Kreirajte ponudu - Zidovi Smart - Dvostruka obloga",
    walls_smart_simple: "Zidovi Smart - Jednostruka obloga",
    walls_smart_double: "Zidovi Smart - Dvostruka obloga",
    not_a_number: "Unesena vrijednost mora biti broj",
    support_type_brida: "Direktni ovjes",
    support_type_tirant: "Žica s ušicom",
    support_type_nonius: "Nonius",
    support_type_tija: "Vijak M8",
    support_type_racord_lemn: "Sidreni ovjes",
    support_type_brida_ac: "Akustični ovjes CD profila",
    support_type_beton: "Beton",
    support_type_zidarie: "Zidanje",
    support_type_tencuieli: "žbuke",
    support_type_altele: "Ostalo",
    support_type_autoportant: "Samonosivi",
    bride: "kravate",
    cu_rezistenta: "sa otporima",
    "Brida": "Direktni ovjes",
    "Tirant": "Žica s ušicom",
    "Nonius": "Nonius",
    "Tija M8": "Vijak M8",
    "Racord lemn": "Sidreni ovjes",
    "Brida AC": "Akustični ovjes CD profila",
    "Beton": "Beton",
    "Zidarie": "Zidanje",
    "Tencuieli": "žbuke",
    "Altele": "Ostalo",
    "Autoportant": "Samonosivi",
    walls_smart: "Zidovi Smart",
    linings_smart: "Obloge Smart",
    ceilings_smart: "Stropovi Smart",
    create_offer_ceiling_smart_suspended_simple: "Kreirajte ponudu - Spušteni Smart Stropovi - Jednostruki",
    create_offer_ceiling_smart_suspended_double: "Kreirajte ponudu - Spušteni Smart Stropovi - Dvostruki",
    ceiling_smart_suspended_simple: "Spušteni Smart Stropovi - Jednostruki",
    ceiling_smart_suspended_double: "Spušteni Smart Stropovi - Dvostruki",
    create_offer_ceiling_smart_autoport_simple: "Kreirajte ponudu - Samonosivi Smart Stropovi - Jednostruki",
    create_offer_ceiling_smart_autoport_double: "Kreirajte ponudu - Samonosivi Smart Stropovi - Dvostruki",
    ceiling_smart_autoport_simple: "Samonosivi Smart Stropovi - Jednostruki",
    ceiling_smart_autoport_double: "Samonosivi Smart Stropovi - Dvostruki",
    create_offer_plating_smart_sticking: "Kreirajte ponudu -  Obloga Smart Lijepljenem",
    plating_smart_sticking: "Obloga Smart Lijepljenem",
    "unit_mm": "mm",
    "unit_cm": "cm",
    "unit_m": "m",
    "unit_mp": "m2",
    "unit_kg": "kg",
    "unit_kg_per_mp": "Kg/mp",
    "unit_db": "dB",
    "unit_min": "min",
    "unit_max": "max",
    "select": "Odaberite",
    "download_selection": "Preuzmite odabrano",
};
