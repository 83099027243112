// Used for colours that you use in Material UI Javascript styles
// ATTENTION: you should also add those under the ***same name*** in /src/css/constants/_colours.scss to avoid confusion
export const colours = {
	black: 'rgba(0, 0, 0, 1)',
	white: 'rgba(255, 255, 255, 1)',
	transparent: 'rgba(0, 0, 0, 1)',
	grey: '#bdbdbd',
	greyHover: '#d3d3d3',
	blue: '#007bff',
	purple: 'rgb(166, 31, 125)',
	purpleHover: 'rgb(151, 28, 114)',
	orange: '#F06D0C',
	orangeHover: '#F06D0C',
	darkBlue: '#00355F',
	shadow: '#00355F33',
};