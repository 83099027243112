export const pl = {
    "login": "ppp_Autentificare",
    "register": "ppp_Inregistreaza un cont nou",
    "change_password": "ppp_Schimbati parola",
    "copyright": "ppp_Copyright",
    "copyright_value": "ppp_Siniat SEE",
    "email_address": "ppp_Adresa de email",
    "address": "ppp_Adresa",
    "locality": "ppp_Localitatea",
    "language": "ppp_Limba",
    "state": "ppp_Judetul",
    "password": "ppp_Parola",
    "forgot_password": "ppp_Ai uitat parola?",
    "enter_account": "ppp_Intra in cont",
    "not_registered": "ppp_Inregistreaza cont nou",
    "walls": "ppp_Pereti",
    "linnings": "ppp_Placari",
    "linnings_f": "ppp_Placari cu fixari",
    "linnings_i": "ppp_Placari independente",
    "linnings_l": "ppp_Placari liniare",
    "linnings_p": "ppp_Placari lipire",
    "linnings_nf": "ppp_Placari Noisy cu fixari",
    "linnings_ni": "ppp_Placari Noisy independente",
    "linnings_nuu": "ppp_Placari Noisy UU",
    "ceilings": "ppp_Plafoane",
    "ceilings_s": "ppp_Plafoane suspendate",
    "ceilings_ss": "ppp_Plafoane autoportante",
    "home": "ppp_Acasa",
    "print": "ppp_Printeaza",
    "personal_information": "ppp_Informatii personale",
    "first_name": "ppp_Prenume",
    "last_name": "ppp_Nume",
    "last_login": "ppp_Ultima logare",
    "logins_number": "ppp_Numar autentificari",
    "phone_number": "ppp_Numar de telefon",
    "status_active": "ppp_Activ",
    "is_admin": "ppp_Admin",
    "created_on": "ppp_Data inregistrare",
    "account_data": "ppp_Datele contului",
    "confirm_password": "ppp_Confirma parola",
    "read_agree": "ppp_Sunt de acord cu ",
    "terms_conditions": "ppp_termene si conditii",
    "finish_register": "ppp_Inregistreaza contul",
    "already_registered": "ppp_Esti deja inregistrat? Autentifica-te",
    "required_field": "ppp_Acest camp este obligatoriu",
    "minimum_characters": "ppp_Numar minim de caractere este __min__",
    "maximum_characters": "ppp_Numar maxim de caractere este __max__ ",
    "valid_email": "ppp_Adresa de email nu este valida",
    "invalid_phone_format": "ppp_Format invalid",
    "reset_password": "ppp_Resetare Parola",
    "reset_password_button": "ppp_Recupereaza parola",
    "back_to_login": "ppp_Inapoi la autentificare",
    "page_loading": "ppp_Pagina se incarca, va rugam asteptati",
    "new_password": "ppp_Parola noua",
    "confirm_new_password": "ppp_Confirma parola noua",
    "save_password": "ppp_Salveaza parola",
    "successfully_registered": "ppp_Inregistrare cu succes",
    "successfully_registered_paragraph": "ppp_Inregistrarea contului a fost efectuata cu succes",
    "successfully_registered_approval": "ppp_Contul dumnevoastra va fi activat in cel mai scurt timp",
    "resend_confirmation": "ppp_Retrimite email-ul de confirmare",
    "account_not_activated": "ppp_Contul nu este activat",
    "account_activated": "ppp_Contul a fost activat",
    "account_activated_description": "ppp_Contul a fost activat cu succes",
    "resend_confirm_account_description_1": "ppp_Completati adresa de email cu care v-ati inregistrati",
    "resend_confirm_account_description_2": "ppp_Odata ce primesti email, apasati pe butonul de Confirmare cont pentru a activa contul",
    "agree_terms_conditions": "ppp_Trebuie sa acceptati termenele si conditiile pentru a continua inregistrarea",
    "use_new_password": "ppp_Parola s-a resetat. Foloseste noua ta parola pentru autentificare",
    "not_activated": "ppp_Nu ati primit email de activare cont? Puteti solicita unul nou aici",
    "resend_confirmation_success": "ppp_Daca contul a fost inregistrat cu aceasta adresa de mail, ar trebui sa primesti un link de confirmare. Te rog sa verifici se sectiunea de spam",
    "successfully_requested_reset_password": "ppp_Cererea de resetare a parolei a fost trimisa via email. Te rugam verifica inclusiv in folderul spam",
    "saved_password": "ppp_Parola a fost salvata. O sa fi redirectionat intr-un timp scurt",
    "january": "ppp_ianuarie",
    "february": "ppp_februarie",
    "march": "ppp_martie",
    "april": "ppp_aprilie",
    "may": "ppp_mai",
    "june": "ppp_iunie",
    "july": "ppp_iulie",
    "august": "ppp_august",
    "september": "ppp_septembrie",
    "october": "ppp_octombrie",
    "november": "ppp_noiembrie",
    "december": "ppp_decembrie",
    "logout": "ppp_Iesire",
    "list_empty": "ppp_Aceasta lista este goala",
    "attach_image": "ppp_Incarca imaginea",
    "file_too_large": "ppp_Acest fisier este prea mare",
    "file_wrong_file_type_spreadsheet": "ppp_Tipul de fisier nu este suportat. Va rugam sa folositi un fisier de tip .xls, .xlsx",
    "numbers_only": "ppp_Numbers only",
    "save": "ppp_Save",
    "back": "ppp_Inapoi",
    "actions": "ppp_Operatii",
    "confirm": "ppp_Confirma",
    "cancel": "ppp_Anuleaza",
    "close": "ppp_Inchide",
    "add_offer": "ppp_Adauga Oferta",
    "manage_offers": "ppp_Ofertele mele",
    "height": "ppp_Inaltime",
    "profile_type": "ppp_Tip profil",
    "fire_resistance": "ppp_Rezistenta la foc",
    "moisture_resistance": "ppp_Rezistenta la umiditate",
    "sound_insulation": "ppp_Vata minerala",
    "sound_insulation_value": "ppp_Izolare acustica (dB)",
    "sound_proof": "ppp_Izolare fonica",
    "back_to_offers": "ppp_Inapoi la oferte",
    "support": "ppp_Tip suport",
    "finishing": "ppp_Finisare",
    "imported_file": "ppp_Fisierul a fost prelucrat cu succes",
    "interax": "ppp_Interax",
    "price": "ppp_Pret (RON)",
    "sap_code": "ppp_Cod SAP",
    "product_name": "ppp_Nume produs",
    "amount": "ppp_Cantitate",
    "square_meter": "ppp_mp",
    "unit_of_measure": "ppp_UM",
    "project_info": "ppp_Informatii Proiect",
    "joint_length": "ppp_Lungime rost",
    "boq_code": "ppp_Denumire BoQ",
    "system_name": "ppp_Denumire sistem",
    "offer_details": "ppp_Detaliile ofertei",
    "compatible_offers": "ppp_Oferte compatibile criteriilor tale",
    "choose_system": "ppp_Alege tipul de sistem",
    "face1Plate1": "ppp_Fata1: Placa1",
    "face1Plate2": "ppp_Fata1: Placa2",
    "face1Plate3": "ppp_Fata1: Placa3",
    "face1Plate4": "ppp_Fata1: Placa4",
    "face2Plate1": "ppp_Fata2: Placa1",
    "face2Plate2": "ppp_Fata2: Placa2",
    "face2Plate3": "ppp_Fata2: Placa3",
    "face2Plate4": "ppp_Fata2: Placa4",
    "more_details": "ppp_Mai multe detalii aici",
    "save_offer": "ppp_Salveaza oferta",
    "interax_sustineri": "ppp_Interax Sustineri",
    "consumuri": "ppp_Necesar de materiale",
    "prindere_superioara": "ppp_Necesar de materiale prindere superioara",
    "surface": "ppp_Suprafata",
    "delete_offer_title": "ppp_Esti sigur ca doresti sa stergi aceasta oferta?",
    "delete_offer_description": "ppp_Nu o sa mai aveti oferta salvata dupa ce o stergeti",
    "deleted_offer": "ppp_Oferta stearsa",
    "delete_session_title": "ppp_Esti sigur ca doresti sa stergi acest proiect?",
    "delete_session_description": "ppp_Nu o sa mai aveti proiectul salvat dupa ce o stergeti",
    "company": "ppp_Organizatie",
    "company_register": "ppp_Numele firmei pe care o reprezentati",
    "project_code": "ppp_Cod proiect Siniat",
    "job": "ppp_Obiectul de activitate",
    "contact_person": "ppp_Persoana de contact",
    "email": "ppp_Email",
    "objective": "ppp_Denumire Obiectiv",
    "type_objective": "ppp_Tip Obiectiv",
    "type_objective_option_1": "ppp_Bloc locuinte",
    "type_objective_option_2": "ppp_Cladire birouri",
    "type_objective_option_3": "ppp_Hala industriala sau depozit",
    "type_objective_option_4": "ppp_Complex comercial",
    "type_objective_option_5": "ppp_Hotel",
    "type_objective_option_6": "ppp_Spital",
    "type_objective_option_7": "ppp_Divertisment(piscina, cinema, sala concrete etc.)",
    "type_objective_option_8": "ppp_Invatamant",
    "location": "ppp_Judet",
    "description": "ppp_Descriere",
    "validation_date": "ppp_Valid pana la data de:",
    "nr": "ppp_Nr crt",
    "delete_session": "ppp_Sterge proiectul",
    "download_session": "ppp_Descarca proiectul",
    "view_session_offers": "ppp_Vizualizeaza ofertele proiectului",
    "edit_session": "ppp_Editeaza proiectul",
    "create_new_session": "ppp_Creeaza un proiect nou",
    "new_offer": "ppp_Creaza oferta noua",
    "edit_offer_plates": "ppp_Editeaza placile ofertei",
    "finish_edit_plates": "ppp_Termina editarea placilor ofertei",
    "import_file": "ppp_Prelucreaza fisier",
    "choose_import": "ppp_Alege tipul de fisier de calcul",
    "imports": "ppp_Import fisiere de calcul",
    "burglary_resistance": "ppp_Rezistenta la efractie",
    "number_of_offers": "ppp_Numarul de oferte create",
    "date_created": "ppp_Data crearii ofertei",
    "back_to_create_offer": "ppp_Inapoi la generarea de oferta",
    "generate_offers": "ppp_Genereaza oferte compatibile",
    "reset_offer": "ppp_Reseteaza campurile ofertei",
    "my_sessions": "ppp_Proiectele mele",
    "manage_users": "ppp_Administrare utilizatori",
    "reports": "ppp_Rapoarte",
    "sessions_offers": "ppp_Proiecte / Oferte",
    "save_session": "ppp_Salveaza proiectul",
    "download": "ppp_Descarca",
    "download_file": "ppp_Descarca fisierul",
    "download_users": "ppp_Descarca lista",
    "next": "ppp_Continua",
    "walls_s": "ppp_Simplu Placat",
    "walls_d": "ppp_Dublu Placat",
    "walls_t": "ppp_Triplu Placat",
    "linnings_s": "ppp_Placari Simple",
    "linnings_d": "ppp_Placari Duble",
    "linnings_t": "ppp_Placari Triple",
    "linnings_q": "ppp_Placari Cvadruple",
    "linnings_ei": "Placari EI180 min (5 placi)",
    "ceilings_plates_s": "ppp_Plafoane Simple",
    "ceilings_plates_d": "ppp_Plafoane Duble",
    "ceilings_plates_t": "ppp_Plafoane Triple",
    "ceilings_plates_q": "ppp_Plafoane Cvadruple",
    "date_validation": "ppp_Data de validare",
    "face": "ppp_fata",
    "exterior": "ppp_exterioara",
    "and": "ppp_si",
    "sistem_weight": "ppp_Greutate Sistem",
    "montant_type": "ppp_Tip montant",
    "upper_guiding": "ppp_Tip ghidaj superior",
    "lower_guiding": "ppp_Tip ghidaj inferior",
    "plating_types": "ppp_Tip placi",
    "plating_type_face_a": "ppp_Tip placi Fata A",
    "plating_type_face_b": "ppp_Tip placi Fata B",
    "upper_support": "ppp_Tip suport superior",
    "wool_type": "ppp_Tip izolatie",
    "thickness_system": "ppp_Grosime Sistem",
    "ceilings_distance": "ppp_Distanta pana la planseu",
    "ceilings_support": "ppp_Tip de sustinere",
    "system_code": "ppp_Cod Sistem",
    "protection_direction": "ppp_Sens Protectie",
    "sound_insulation_label": "ppp_Izolare Acustica",
    "plating_type_lower_face": "ppp_Tip placi fata inferioara",
    "plating_type_upper_face": "ppp_Tip placi fata superioara",
    "main_structure": "ppp_Tip structura principala",
    "secondary_structure": "ppp_Tip structura secundara",
    "parameter_structure": "ppp_Tip ghidaj perimetral",
    "interax_secondary": "ppp_Interax structura secundara",
    "type_intermediate_plate": "ppp_Tip placa intermediara",
    "distance": "ppp_Distanta dintre structuri",
    "structure_type": "ppp_Tip Sustineri",
    "manage_account": "ppp_Contul meu",
    "edit_account": "ppp_Editeaza datele contul",
    "finish_edit_account": "ppp_Salveaza datele contului",
    "edit_password": "ppp_Editeaza Parola",
    "edit_password_confirm": "ppp_Editeaza parola utilizatorului",
    "edit_password_cancel": "ppp_Anuleaza editarea parolei",
    "based_plates": "ppp_Placa de baza a sistemului",
    "walls_s_asimetric": "ppp_Asimetrici",
    "walls_s_intermediar_d": "ppp_Dublu Intermediar",
    "walls_s_intermediar_t": "ppp_Triplu Intermediar",
    "intermediate_plate": "ppp_Placa Intermediara",
    "too_much": "ppp_Valoarea este prea mare",
    "max_opening": "ppp_Deschidere maxima",
    "price_per_unit": "ppp_Pret(RON) / mp",
    "rows_per_page": "ppp_Randuri pe pagina: ",
    "no_range": "ppp_Nu respecta intervalul!",
    "no_offers": "ppp_Momentan nu exista nici o oferta creata",
    "passwords_must_match": "ppp_Parola nu este aceeasi! Va rugam sa o introduceti din nou",
    "description_popup": "ppp_Aici regasiti primele 6 cele mai ieftine sisteme configurate conform cerinteleor dumneavoastra. Pe coloane puteti urmari o mare parte dintre particularitatile fiecarui sistem, pentru a putea selecta sistemul dorit. Apasati OK pentru a trece mai departe",
    "search": "ppp_Cauta",
    "less_table_cols": "ppp_Compacteaza tabelul",
    "report": "ppp_Raport",
    "import": "ppp_Import",
    "materials": "ppp_Consumuri",
    "allowed_plates": "ppp_Placi Permise",
    "products": "ppp_Produse",
    "ceilings_plates_s_tooltip": "ppp_Plafoane cu o placa",
    "ceilings_plates_d_tooltip": "ppp_Plafoane cu doua placi",
    "ceilings_plates_t_tooltip": "ppp_Plafoane cu trei placi",
    "ceilings_plates_q_tooltip": "ppp_Plafoane cu patru placi",
    "separative": "ppp_Separativi",
    "linnings_noisy_double": "ppp_Placari Noisy Duble",
    "linnings_noisy_triple": "ppp_Placari Noisy Triple",
    "price_list": "ppp_Lista de preturi",
    "attach_file": "ppp_Incarcati fisierul",
    "attach_file_for": "ppp_Incarcati fisierul pentru importul de",
    "attach_file_last_timestamp": "ppp_Ultima actualizare pentru __file__ a fost la",
    "attach_file_last_file": "ppp_Ultimul fisier incarcat",
    "thickness": "ppp_Grosime",
    "choose_project": "ppp_Alege un proiect pentru aceasta oferta",
    "create_new_project": "ppp_Creeaza proiect nou",
    "quit": "ppp_Renunta",
    "select_project": "ppp_Selecteaza proiectul",
    "unspecified": "ppp_nespecificat",
    "systemType": "ppp_Tip sistem",
    "PDF_details": "ppp_Detalii PDF",
    "DWG_details": "ppp_Detalii DWG",
    "brochures_sistems": "ppp_Brosura sisteme",
	'systemCode': 'Cod sistem',
	'technical_characteristics': 'Caracteristici Tehnice',
	'value': 'Valoare',
	'more_details_here': 'Mai multe detalii aici',
	'create_offer_walls_d_simple': "Creare oferta - Pereti D - Simplu Placat",
	'create_offer_walls_d_double': "Creare oferta - Pereti D - Dublu Placat",
	'create_offer_walls_d_triple': "Creare oferta - Pereti D - Triplu Placat",
	'create_offer_walls_s_double': "Creare oferta - Pereti S - Dublu Placat",
	'create_offer_walls_s_triple': "Creare oferta - Pereti S - Triplu Placat",
	'create_offer_walls_s_asimetric': "Creare oferta - Pereti S - Asimetrici",
	'create_offer_walls_s_double_intermediate': "Creare oferta - Pereti S - Dublu Intermediar",
	'create_offer_walls_s_triple_intermediate': "Creare oferta - Pereti S - Triplu Intermediar",
	'create_offer_walls_sl_simple': "Creare oferta - Pereti SL - Simplu Placat",
	'create_offer_walls_sl_double': "Creare oferta - Pereti SL - Dublu Placat",
	'create_offer_walls_sl_triple': "Creare oferta - Pereti SL - Triplu Placat",
	'create_offer_walls_sla_triple': "Creare oferta - Pereti SLA - Triplu Placat",
	'create_offer_plating_fix_simple': "Creare oferta - Placari cu fixari - Simple",
	'create_offer_plating_fix_double': "Creare oferta - Placari cu fixari - Duble",
	'create_offer_plating_fix_triple': "Creare oferta - Placari cu fixari - Triple",
	'create_offer_plating_fix_quadruple': "Creare oferta - Placari cu fixari - Cvadruple",
    'create_offer_plating_indep_simple': "Creare oferta - Placari independente - Simple",
	'create_offer_plating_indep_double': "Creare oferta - Placari independente - Duble",
	'create_offer_plating_indep_triple': "Creare oferta - Placari independente - Triple",
	'create_offer_plating_indep_quadruple': "Creare oferta - Placari independente - Cvadruple",
    'plating_indep_ei': "Placari independente - EI180 min (5 placi)",
    'plating_fix_ei': "Placari cu fixari - EI180 min (5 placi)",
    'create_offer_plating_indep_ei': "Creare oferta - Placari independente - EI180 min (5 placi)",
    'create_offer_plating_liniar_double': "Creare oferta - Placari liniare - Duble",
	'create_offer_plating_liniar_triple': "Creare oferta - Placari liniare - Triple",
	'create_offer_plating_liniar_quadruple': "Creare oferta - Placari liniare - Cvadruple",
	'create_offer_plating_sticking': "Creare oferta - Placari Lipire",
    'create_offer_noisy_plating_fix_triple': "Creare oferta - Placari Noisy cu fixari - Triple",
    'create_offer_noisy_plating_indep_double': "Creare oferta - Placari Noisy independente - Duble",
    'create_offer_noisy_plating_indep_triple': "Creare oferta - Placari Noisy independente - Triple",
    'create_offer_noisy_plating_uu_double': "Creare oferta - Placari Noisy UU - Duble",
    'create_offer_noisy_plating_uu_triple': "Creare oferta - Placari Noisy UU - Triple",
	'create_offer_ceiling_suspended_simple': "Creare oferta - Plafoane Suspendate - Simple",
	'create_offer_ceiling_suspended_double': "Creare oferta - Plafoane Suspendate - Duble",
	'create_offer_ceiling_suspended_triple': "Creare oferta - Plafoane Suspendate - Triple",
	'create_offer_ceiling_suspended_quadruple': "Creare oferta - Plafoane Suspendate - Cvadruple",
	'create_offer_ceiling_autoport_simple': "Creare oferta - Plafoane Autoportante - Simple",
	'create_offer_ceiling_autoport_double': "Creare oferta - Plafoane Autoportante - Duble",
	'create_offer_ceiling_autoport_triple': "Creare oferta - Plafoane Autoportante - Triple",
	'create_offer_ceiling_autoport_quadruple': "Creare oferta - Plafoane Autoportante - Cvadruple",
    "download_offer": "ppp_Descarca Oferta",
    "delete_offer" : "Sterge Oferta",
    "compatible_offers:" : "Oferte Compatibile",
    "country" : "Tara",
};
