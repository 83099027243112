import { ro }  from './ro';
import { en }  from './en';
import { gr }  from './gr';
import { pl }  from './pl';
import { bg }  from './bg';
import { cr }  from './cr';

export const appTexts = {
	ro: ro,
	en: en,
	bg: bg,
	cr: cr,
	gr: gr,
	pl: pl
};
