export const en = {
    'login': 'Login',
    'register': 'Create a new account',
    'change_password': 'Change password',
    'copyright': 'Copyright',
    'copyright_value': 'Siniat SEE',
    'email_address': 'E-mail',
    'address': 'Address',
    'locality': 'City',
    'language': 'Language',
    'state': 'County',
    'password': 'Password',
    'forgot_password': 'Forgot your password?',
    'enter_account': 'Log in',
    'not_registered': 'Create a new account',
    'walls': 'Walls',
    'linnings': 'Lining',
    'linnings_f': 'Lining with fasteners',
    'linnings_i': 'Stand-alone lining',
    'linnings_l': 'Linear lining',
    'linnings_p': 'Lining on adhesive',
    'linnings_nf': 'Noisy lining with fasteners',
    'linnings_ni': 'Stand-alone Noisy lining',
    'linnings_nuu': 'Noisy UU lining',
    'ceilings': 'Ceilings',
    'ceilings_s': 'Suspended ceilings',
    'ceilings_ss': 'Self-supporting ceilings',
    'home': 'Home',
    'print': 'Print',
    'personal_information': 'Personal information',
    'first_name': 'First name',
    'last_name': 'Last name',
    'last_login': 'Last login',
    'logins_number': 'Number of logins',
    'phone_number': 'Phone number',
    'status_active': 'Active',
    'is_admin': 'Admin',
    'created_on': 'Registration date',
    'account_data': 'Account information',
    'confirm_password': 'Confirm password',
    'read_agree': 'I accept',
    'terms_conditions': 'the terms and conditions',
    'finish_register': 'Register your account',
    'already_registered': 'Are you already registered? Log in',
    'required_field': 'This field is mandatory',
    'minimum_characters': 'The minimum number of characters is __min__',
    'maximum_characters': 'The maximum number of characters is __max__',
    'valid_email': 'Invalid e-mail',
    'invalid_phone_format': 'Invalid format',
    'reset_password': 'Reset password',
    'reset_password_button': 'Recover password',
    'back_to_login': 'Back to login',
    'page_loading': 'The page is loading, please wait',
    'new_password': 'New password',
    'confirm_new_password': 'Confirm new password',
    'save_password': 'Save password',
    'successfully_registered': 'Registration successful',
    'successfully_registered_paragraph': 'The account registration was successful',
    'successfully_registered_approval': 'Your account will be activated as soon as possible',
    'resend_confirmation': 'Resend confirmation email',
    'account_not_activated': 'Your account is not activated',
    'account_activated': 'Your account has been activated',
    'account_activated_description': 'Your account was successfully activated',
    'resend_confirm_account_description_1': 'Fill in the email address you used to register',
    'resend_confirm_account_description_2': 'Once you receive the email, click on the Confirm Account button to activate your account',
    'agree_terms_conditions': 'You must accept the terms and conditions to resume registration',
    'use_new_password': 'The password was reset. Use your new password to log in',
    'not_activated': 'Haven’t received your account activation email? You can request a new one here',
    'resend_confirmation_success': 'If your account was registered with this email address, you should receive a confirmation link. Please check the spam section',
    'successfully_requested_reset_password': 'The password reset request was sent by email. Please check your spam folder',
    'saved_password': 'Your password has been saved. You will be redirected shortly',
    'january': 'January',
    'february': 'February',
    'march': 'March',
    'april': 'April',
    'may': 'May',
    'june': 'June',
    'july': 'July',
    'august': 'August',
    'september': 'September',
    'october': 'October',
    'november': 'November',
    'december': 'December',
    'logout': 'Quit',
    'list_empty': 'This list is empty',
    'attach_image': 'Upload image',
    'file_too_large': 'This file is too big',
    'file_wrong_file_type_spreadsheet': 'This file type is not supported. Please use .xls or .xlsx files',
    'numbers_only': 'Numbers only',
    'save': 'Save',
    'back': 'Back',
    'actions': 'Operations',
    'confirm': 'Confirm',
    'cancel': 'Cancel',
    'close': 'Close',
    'add_offer': 'Add offer',
    'manage_offers': 'My offers',
    'height': 'Height',
    'profile_type': 'Profile type',
    'fire_resistance': 'Fire resistance',
    'moisture_resistance': 'Moisture resistance',
    'sound_insulation': 'Mineral wool',
    'sound_insulation_value': 'Acoustic insulation (dB)',
    'sound_proof': 'Acoustic insulation',
    'back_to_offers': 'Return to offers',
    'support': 'Support type',
    'finishing': 'Finishing',
    'imported_file': 'The file was processed successfully',
    'interax': 'Interaxis',
    'price': 'Price (EURO)',
    'sap_code': 'SAP Code',
    'product_name': 'Product name',
    'amount': 'Quantity',
    'square_meter': 'sqm',
    'unit_of_measure': 'MU',
    'project_info': 'Project information',
    'joint_length': 'Joint length',
    'boq_code': 'BoQ name',
    'system_name': 'System name',
    'offer_details': 'Offers details',
    'compatible_offers': 'Offers that meet your criteria',
    'choose_system': 'Select the system type',
    'face1Plate1': 'Side1: Plasterboard1',
    'face1Plate2': 'Side1: Plasterboard2',
    'face1Plate3': 'Side1: Plasterboard3',
    'face1Plate4': 'Side1: Plasterboard4',
    'face1Plate5': 'Side1: Plasterboard5',
    'face2Plate1': 'Side2: Plasterboard1',
    'face2Plate2': 'Side2: Plasterboard2',
    'face2Plate3': 'Side2: Plasterboard3',
    'face2Plate4': 'Side2: Plasterboard4',
    'more_details': 'More details here',
    'save_offer': 'Save the offer ',
    'interax_sustineri': 'Interaxis spacing for supports',
    'consumuri': 'Required materials',
    'prindere_superioara': 'Bill of quantities for the top fixing',
    'surface': 'Surface',
    'delete_offer_title': 'Are you sure you want to delete this offers?',
    'delete_offer_description': 'After you delete it, your offers won’t be saved anymore',
    "deleted_session_offer": 'After you delete it, your session offer won’t be saved anymore',
    'deleted_offer': 'Deleted offer',
    'delete_session_title': 'Are you sure you want to delete this project?',
    'delete_session_description': 'After you delete it, your project won’t be saved anymore',
    'company': 'Organisation',
    'company_register': 'Name of the company you represent',
    'project_code': 'Siniat Project Code',
    'job': 'Scope of activity',
    'contact_person': 'Contact person',
    'email': 'Email',
    'objective': 'Name of building project',
    'type_objective': 'Type of building project',
    'type_objective_option_1': 'Block of flats',
    'type_objective_option_2': 'Office building',
    'type_objective_option_3': 'Industrial building or warehouse',
    'type_objective_option_4': 'Shopping mall',
    'type_objective_option_5': 'Hotel',
    'type_objective_option_6': 'Hospital',
    'type_objective_option_7': 'Entertainment (pool, cinema, concert hall, etc.)',
    'type_objective_option_8': 'Education',
    'location': 'County',
    'description': 'Description',
    'validation_date': 'Valid until:',
    'nr': 'Ref. no.',
    'delete_session': 'Delete project',
    'download_session': 'Download project',
    'view_session_offers': 'View offers for the project',
    'edit_session': 'Edit project',
    'create_new_session': 'Create a new project',
    'new_offer': 'Create a new offer ',
    'edit_offer_plates': 'Edit plasterboards included in the offer ',
    'finish_edit_plates': 'Finish editing plasterboards included in the offer ',
    'import_file': 'Process file',
    'choose_import': 'Choose type of calculation file',
    'imports': 'Import calculation files',
    'burglary_resistance': 'Burglary resistance',
    'number_of_offers': 'Number of offers created',
    'date_created': 'Date of offer creation',
    'back_to_create_offer': 'Back to generating offers',
    'generate_offers': 'Generate compatible offers',
    'reset_offer': 'Reset offer fields',
    'my_sessions': 'My projects',
    'manage_users': 'User management',
    'reports': 'Reports',
    'sessions_offers': 'Projects/Offers',
    'save_session': 'Save project',
    'download': 'Download',
    'download_file': 'Download file',
    'download_users': 'Download list',
    'next': 'Continue',
    'walls_s': 'Simple layer',
    'walls_d': 'Double layer',
    'walls_t': 'Triple layer',
    'linnings_s': 'Simple layer',
    'linnings_d': 'Double layer',
    'linnings_t': 'Triple layer',
    'linnings_q': 'Quadruple layer',
    "linnings_ei": "Lining EI180 min (5 plasterboard)",
    'ceilings_plates_s': 'Simple layer',
    'ceilings_plates_d': 'Double layer',
    'ceilings_plates_t': 'Triple layer',
    'ceilings_plates_q': 'Quadruple layer',
    'date_validation': 'Validation date',
    'face': 'side',
    'exterior': 'external',
    'and': 'and',
    'sistem_weight': 'System weight',
    'montant_type': 'Stud type',
    'upper_guiding': 'Track type, top',
    'lower_guiding': 'Track type, bottom',
    'plating_types': 'Plasterboard type',
    'plating_type_face_a': 'Plasterboard type, Side A',
    'plating_type_face_b': 'Plasterboard type, Side B',
    'upper_support': 'Support type, top',
    'wool_type': 'Insulation type',
    'thickness_system': 'System thickness',
    'ceilings_distance': 'Distance to the floor',
    'ceilings_support': 'Type of support',
    'system_code': 'System code',
    'protection_direction': 'Direction of protection',
    'sound_insulation_label': 'Acoustic insulation',
    'plating_type_lower_face': 'Plasterboard type, bottom side',
    'plating_type_upper_face': 'Plasterboard type, top side',
    'main_structure': 'Main structure type',
    'secondary_structure': 'Secondary structure type',
    'parameter_structure': 'Perimeter track type',
    'interax_secondary': 'Secondary structure interaxis',
    'type_intermediate_plate': 'Type of intermediary plasterboard',
    'distance': 'Distance between structures',
    'structure_type': 'Type of supports',
    'manage_account': 'My account',
    'edit_account': 'Edit account information',
    'finish_edit_account': 'Save account information',
    'edit_password': 'Edit password',
    'edit_password_confirm': 'Edit user password',
    'edit_password_cancel': 'Cancel password editing',
    'based_plates': 'Main system board',
    'walls_s_asimetric': 'Asymmetrical',
    'walls_s_intermediar_d': 'Double intermediate',
    'walls_s_intermediar_t': 'Triple intermediate',
    'intermediate_plate': 'Intermediate plasterboard',
    'too_little': 'This value is too low',
    'too_much': 'This value is too high',
    'max_opening': 'Max. opening',
    'price_per_unit': 'Price (EURO)/sqm',
    'rows_per_page': 'Rows per page:',
    'no_range': 'Out of range!',
    'no_offers': 'There is currently no offer being created',
    'passwords_must_match': 'The password is different! Please enter it again',
    'description_popup': 'Here are the top 6 cheapest systems configured according to your requirements. In the columns you can find many of the features of each system, so that you can select the one you want. Click OK to continue',
    'search': 'Search',
    'less_table_cols': 'Collapse table',
    'report': 'Report',
    'import': 'Import',
    'materials': 'Consumptions',
    'allowed_plates': 'Allowed boards',
    'products': 'Products',
    'ceilings_plates_s_tooltip': 'Single layer ceilings',
    'ceilings_plates_d_tooltip': 'Double layer ceilings',
    'ceilings_plates_t_tooltip': 'Triple layer ceilings',
    'ceilings_plates_q_tooltip': 'Quadruple layer ceilings',
    'separative': 'Separative type',
    'linnings_noisy_double': 'Double Noisy lining',
    'linnings_noisy_triple': 'Triple Noisy lining',
    'price_list': 'Price list',
    'attach_file': 'Upload file',
    'attach_file_for': 'Upload the file to import',
    'attach_file_last_timestamp': 'Last update for __file__ occurred at',
    'attach_file_last_file': 'Last uploaded file',
    'product': 'Product',
    'thickness': 'Thickness',
    'choose_project': 'Choose a project for this offer',
    'select_project': 'Select project',
    'create_new_project': 'Create new project',
    'quit': 'Quit',
    'unspecified': 'unspecified',
    "systemType": "System Type",
    "PDF_details": "PDF Details",
    "DWG_details": "DWG Details",
    "brochures_sistems": "Brochure for systems",
    'systemCode': 'System Code',
    'technical_characteristics': 'Technical Characteristics',
    'value': 'Value',
    'more_details_here': 'More Details Here',
    "attach_file_by": "Attached file by ",
    'create_offer_walls_d_simple': "Create Offer - Walls D - Simple lining",
    'create_offer_walls_d_double': "Create Offer - Walls D - Double lining",
    'create_offer_walls_d_triple': "Create Offer - Walls D - Triple lining",
    'create_offer_walls_s_double': "Create Offer - Walls S - Double lining",
    'create_offer_walls_s_triple': "Create Offer - Walls S - Triple lining",
    'create_offer_walls_s_asimetric': "Create Offer - Walls S - Asymmetricals",
    'create_offer_walls_s_double_intermediate': "Create Offer - Walls S - Double Intermediate",
    'create_offer_walls_s_triple_intermediate': "Create Offer - Walls S - Triple Intermediate",
    'create_offer_walls_sl_simple': "Create Offer - Walls SL - Simple lining",
    'create_offer_walls_sl_double': "Create Offer - Walls SL - Double lining",
    'create_offer_walls_sl_triple': "Create Offer - Walls SL - Triple lining",
    'create_offer_walls_sla_triple': "Create Offer - Walls SLA - Triple lining",
    'create_offer_plating_fix_simple': "Create Offer - Lining with fasteners - Simple",
    'create_offer_plating_fix_double': "Create Offer - Lining with fasteners - Double",
    'create_offer_plating_fix_triple': "Create Offer - Lining with fasteners - Triple",
    'create_offer_plating_fix_quadruple': "Create Offer - Lining with fasteners - Quadruple",
    'create_offer_plating_fix_ei': "Create Offer - Lining with fasteners - EI180 min (5 plasterboard)",
    'create_offer_plating_indep_simple': "Create Offer - Stand-Alone Lining - Simple",
    'create_offer_plating_indep_double': "Create Offer - Stand-Alone Lining - Double",
    'create_offer_plating_indep_triple': "Create Offer - Stand-Alone Lining - Triple",
    'create_offer_plating_indep_quadruple': "Create Offer - Stand-Alone Lining - Quadruple",
    'create_offer_plating_indep_ei': "Create Offer - Stand-alone Lining - EI180 min (5 plasterboard)",
    'create_offer_plating_liniar_double': "Create Offer - Linear Lining - Double",
    'create_offer_plating_liniar_triple': "Create Offer - Linear Lining - Triple",
    'create_offer_plating_liniar_quadruple': "Create Offer - Linear Lining - Quadruple",
    'create_offer_plating_liniar_cv': "Create Offer - Linear Lining - EI180 min (5 plasterboard)",
    'create_offer_plating_sticking': "Create Offer - Glued Lining",
    'create_offer_noisy_plating_fix_triple': "Create Offer - Noisy Lining with fasteners - Triple",
    'create_offer_noisy_plating_indep_double': "Create Offer - Stand-Alone Noisy Lining - Double",
    'create_offer_noisy_plating_indep_triple': "Create Offer - Stand-Alone Noisy Lining - Triple",
    'create_offer_noisy_plating_uu_double': "Create Offer - Noisy UU Lining - Double",
    'create_offer_noisy_plating_uu_triple': "Create Offer - Noisy UU Lining - Triple",
    'create_offer_ceiling_suspended_simple': "Create Offer - Suspended Ceilings - Simple",
    'create_offer_ceiling_suspended_double': "Create Offer - Suspended Ceilings - Double",
    'create_offer_ceiling_suspended_triple': "Create Offer - Suspended Ceilings - Triple",
    'create_offer_ceiling_suspended_quadruple': "Create Offer - Suspended Ceilings - Quadruple",
    'create_offer_ceiling_autoport_simple': "Create Offer - Self-Supporting Ceilings - Simple",
    'create_offer_ceiling_autoport_double': "Create Offer - Self-Supporting Ceilings - Double",
    'create_offer_ceiling_autoport_triple': "Create Offer - Self-Supporting Ceilings - Triple",
    'create_offer_ceiling_autoport_quadruple': "Create Offer - Self-Supporting Ceilings - Quadruple",
    "download_offer": "Download Offer",
    "delete_offer" : "Delete Offer",
    "compatible_offers:" : "Compatible Offers",
    "country" : "Country",
    "any": "Any",
    "one_face": "One face",
    "one_exterior_face": "One exterior face",
    "two_faces": "Two faces",
    "with_mineral_wool": "With Mineral wool",
    "without_mineral_wool": "Without Mineral wool",
    "without_finishing": "Without Finishing",
    "with_finishing": "With Finishing",
    "Planseu beton armat": "Reinforced Concrete",
    "Tabla cutata de acoperis": "Corrugated Roofing Sheet",
    "beam_type": "Beam Type",
    "minute": "minutes",
    "simple_structure": "simple structure",
    "double_structure": "double structure",
    "one_face_cementex": "One face Cementex",
    "without": "Without",
    "vinclu": "corner",
    "dont_show_message": "Don't show this message again",
    "principal_structure": "Principal Structure",
    "secondary_structure": "Secondary Structure",
    "change_county": "Change Counties",
    "save_county": "Save Counties",
    "county": "Counties",
    "access": "Access",
    "Da": "Yes",
    "Nu": "No",
    "RON": "EURO",
    "Fara": "Without",
    "interaxSustineri": "Interax Support",
    "direction_0": "Unspecified ",
    "direction_1": "Protection from the bottom to the top",
    "direction_2": "Protection from the top to the bottom",
    "direction_3": "Protection both directions",
    "walls_d_simple": "Walls D - Simple lining",
    "walls_d_double": "Walls D - Double lining",
    "walls_d_triple": "Walls D - Triple lining",
    "walls_s_double": "Walls S - Double lining",
    "walls_s_triple": "Walls S - Triple lining",
    "walls_s_asimetric": "Walls S - Asymmetricals",
    "walls_s_double_intermediate": "Walls S - Double Intermediate",
    "walls_s_triple_intermediate": "Walls S - Triple Intermediate",
    "walls_sl_simple": "Walls SL - Simple lining",
    "walls_sl_double": "Walls SL - Double lining",
    "walls_sl_triple": "Walls SL - Triple lining",
    "walls_sla_triple": "Walls SLA - Triple lining",
    "plating_fix_simple": "Lining with fasteners - Simple",
    "plating_fix_double": "Lining with fasteners - Double",
    "plating_fix_triple": "Lining with fasteners - Triple",
    "plating_fix_quadruple": "Lining with fasteners - Quadruple",
    'plating_fix_ei': "Lining with fasteners - EI180 min (5 plasterboard)",
    "plating_indep_simple": "Stand-Alone Lining - Simple",
    "plating_indep_double": "Stand-Alone Lining - Double",
    "plating_indep_triple": "Stand-Alone Lining - Triple",
    "plating_indep_quadruple": "Stand-Alone Lining - Quadruple",
    "plating_indep_ei": "Stand-alone Lining - EI180 min (5 plasterboard)",
    "plating_liniar_double": "Linear Lining - Double",
    "plating_liniar_triple": "Linear Lining - Triple",
    "plating_liniar_quadruple": "Linear Lining - Quadruple",
    "plating_liniar_cv": "Linear Lining - EI180 min (5 plasterboard)",
    "plating_sticking": "Glued Lining",
    "noisy_plating_fix_triple": "Noisy Lining with fasteners - Triple",
    "noisy_plating_indep_double": "Stand-Alone Noisy Lining - Double",
    "noisy_plating_indep_triple": "Stand-Alone Noisy Lining - Triple",
    "noisy_plating_uu_double": "Noisy UU Lining - Double",
    "noisy_plating_uu_triple": "Noisy UU Lining - Triple",
    "ceiling_suspended_simple": "Suspended Ceilings - Simple",
    "ceiling_suspended_double": "Suspended Ceilings - Double",
    "ceiling_suspended_triple": "Suspended Ceilings - Triple",
    "ceiling_suspended_quadruple": "Suspended Ceilings - Quadruple",
    "ceiling_autoport_simple": "Self-Supporting Ceilings - Simple",
    "ceiling_autoport_double": "Self-Supporting Ceilings - Double",
    "ceiling_autoport_triple": "Self-Supporting Ceilings - Triple",
    "ceiling_autoport_quadruple": "Self-Supporting Ceilings - Quadruple",
    "walls_smart_simple": "Walls Smart - Simple lining",
    "walls_smart_double": "Walls Smart - Double lining",
    "not_a_number": "Input must be a number",
    "support_type_brida": "Flange",
    "support_type_tirant": "Tie rod",
    "support_type_nonius": "Nonius",
    "support_type_tija": "M8 threaded hanger",
    "support_type_racord_lemn": "Wood connection",
    "support_type_brida_ac": "Acoustic flange",
    "support_type_beton": "Concrete",
    "support_type_zidarie": "Brick",
    "support_type_tencuieli": "Plasters",
    "support_type_altele": "Other",
    "support_type_autoportant": "Autoportant",
    "Brida": "Flange",
    "Tirant": "Tie rod",
    "Nonius": "Nonius",
    "Tija M8": "M8 threaded hanger",
    "Racord lemn": "Wood connection",
    "Brida AC": "Acoustic flange",
    "Beton": "Concrete",
    "Zidarie": "Brick",
    "Tencuieli": "Plasters",
    "Altele": "Other",
    "Autoportant": "Autoportant",
    "bride": "ties",
    "cu_rezistenta": "with resistances",
    'create_offer_walls_smart_simple' : "Create Offer - Walls Smart - Simple lining",
    'create_offer_walls_smart_double' : "Create Offer - Walls Smart - Double lining",
    "walls_smart": "Walls Smart",
    "linings_smart": "Linings Smart",
    "ceilings_smart": "Ceilings Smart",
    "create_offer_plating_smart_fix_simple": "Create Offer - Lining Smart with fasteners - Simple",
    "create_offer_plating_smart_fix_double": "Create Offer - Lining Smart with fasteners - Double",
    "plating_smart_fix_simple": "Lining Smart with fasteners - Simple",
    "plating_smart_fix_double": "Lining Smart with fasteners - Double",
    "create_offer_plating_smart_indep_simple": "Create Offer - Stand-Alone Lining Smart - Simple",
    "create_offer_plating_smart_indep_double": "Create Offer - Stand-Alone Lining Smart - Double",
    "plating_smart_indep_simple": "Stand-Alone Lining Smart - Simple",
    "plating_smart_indep_double": "Stand-Alone Lining Smart - Double",
    "create_offer_ceiling_smart_suspended_simple": "Create Offer - Suspended Smart Ceilings - Simple",
    "create_offer_ceiling_smart_suspended_double": "Create Offer - Suspended Smart Ceilings - Double",
    "ceiling_smart_suspended_simple": "Suspended Smart Ceilings - Simple",
    "ceiling_smart_suspended_double": "Suspended Smart Ceilings - Double",
    "create_offer_ceiling_smart_autoport_simple": "Create Offer - Self-Supporting Smart Ceilings - Simple",
    "create_offer_ceiling_smart_autoport_double": "Create Offer - Self-Supporting Smart Ceilings - Double",
    "ceiling_smart_autoport_simple": "Self-Supporting Smart Ceilings - Simple",
    "ceiling_smart_autoport_double": "Self-Supporting Smart Ceilings - Double",
    "create_offer_plating_smart_sticking": "Create Offer - Glued Smart Lining",
    "plating_smart_sticking": "Glued Smart Lining",
    "unit_mm": "mm",
    "unit_cm": "cm",
    "unit_m": "m",
    "unit_mp": "m2",
    "unit_kg": "kg",
    "unit_kg_per_mp": "Kg/mp",
    "unit_db": "dB",
    "unit_min": "min",
    "unit_max": "max",
    "select": "Select",
    "download_selection": "Download selection",
    "404": "We are sorry, but the page is not available",
};
