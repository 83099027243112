export const bg = {
    "login": "Влизане",
    "register": "Създаване на нов акаунт",
    "change_password": "Промяна на паролата",
    "copyright": "Авторско право",
    "copyright_value": "Siniat SEE",
    "email_address": "E-mail",
    "address": "Адрес",
    "locality": "Град",
    "language": "език",
    "state": "Страна",
    "password": "Парола",
    "forgot_password": "Забравена парола?",
    "enter_account": "Влизане",
    "not_registered": "Създаване на нов акаунт",
    "walls": "Стени",
    "linnings": "Предстеннa обшивкa",
    "linnings_f": "Предстеннa обшивкa с директен окачвач",
    "linnings_i": "Свободно стояща обшивка",
    "linnings_l": "Шахтова стена",
    "linnings_p": "Обшивка с лепене",
    "linnings_nf": "Акустична обшивка Noisy с допълнително закрепване",
    "linnings_ni": "Свободно стояща обшивка Noisy",
    "linnings_nuu": "Обшивка Noisy UU",
    "ceilings": "Тавани",
    "ceilings_s": "Окачени тавани",
    "ceilings_ss": "Самоносещи тавани",
    "home": "Начало",
    "print": "Печат",
    "personal_information": "Лична информация",
    "first_name": "Име",
    "last_name": "Фамилия",
    "last_login": "Последно влизане",
    "logins_number": "Брой влизания",
    "phone_number": "Телефонен номер",
    "status_active": "Активен",
    "is_admin": "Администратор",
    "created_on": "Дата на регистрация",
    "account_data": "Информация за профила",
    "confirm_password": "Потвърди паролата",
    "read_agree": "Приемам",
    "terms_conditions": "правила и условия",
    "finish_register": "Регистрирайте акаунта си",
    "already_registered": "Регистриран ли си вече? Влизане",
    "required_field": "Това поле е задължително",
    "minimum_characters": "Минималният брой знаци е __min__",
    "maximum_characters": "Максималният брой знаци е __max__",
    "valid_email": "Грешен имейл",
    "invalid_phone_format": "Невалиден формат",
    "reset_password": "Нулиране на паролата",
    "reset_password_button": "Възстановяване на парола",
    "back_to_login": "Обратно към влизане",
    "page_loading": "Страницата се зарежда, моля изчакайте",
    "new_password": "Нова парола",
    "confirm_new_password": "Потвърждение на новата парола",
    "save_password": "Запази паролата",
    "successfully_registered": "Успешна регистрация",
    "successfully_registered_paragraph": "Регистрацията на акаунта беше успешна",
    "successfully_registered_approval": "Вашият акаунт ще бъде активиран възможно най-скоро",
    "resend_confirmation": "Изпрати отново имейл за потвърждение",
    "account_not_activated": "Вашият акаунт не е активиран",
    "account_activated": "Вашият акаунт е активиран",
    "account_activated_description": "Вашият акаунт бе активиран успешно",
    "resend_confirm_account_description_1": "Попълнете имейл адреса, който сте използвали за регистрация",
    "resend_confirm_account_description_2": "След като получите имейла, кликнете върху бутона Потвърди Акаунта, за да активирате акаунта си",
    "agree_terms_conditions": "Трябва да приемете общите условия, за да възобновите регистрацията",
    "use_new_password": "Паролата беше нулирана. Използвайте новата си парола, за да влезете",
    "not_activated": "Не сте получили имейла за активиране на акаунта си? Можете да заявите нов тук",
    "resend_confirmation_success": "Ако акаунтът ви е регистриран с този имейл адрес, трябва да получите връзка за потвърждение. Моля, проверете папка спам",
    "successfully_requested_reset_password": "Заявката за нулиране на паролата беше изпратена по имейл. Моля, проверете папка спам",
    "saved_password": "Вашата парола е запазена. Скоро ще бъдете пренасочени",
    "january": "Януари",
    "february": "Февруари",
    "march": "Март",
    "april": "Април",
    "may": "Май",
    "june": "Юни",
    "july": "Юли",
    "august": "Август",
    "september": "Септември",
    "october": "Октомври",
    "november": "Ноември",
    "december": "Декември",
    "logout": "Излез",
    "list_empty": "Този списък е празен",
    "attach_image": "Качи изображение",
    "file_too_large": "Този файл е твърде голям",
    "file_wrong_file_type_spreadsheet": "Този файл формат не се поддържа. Моля, използвайте .xls или .xlsx файлове",
    "numbers_only": "Само числа",
    "save": "Запазете",
    "back": "Обратно",
    "actions": "Операции",
    "confirm": "Потвърдете",
    "cancel": "Откажете",
    "close": "Затворете",
    "add_offer": "Добавете оферта",
    "manage_offers": "Моите оферти",
    "height": "Височина",
    "profile_type": "Тип профил",
    "fire_resistance": "Пожароустойчивост",
    "moisture_resistance": "Устойчивост на влага",
    "sound_insulation": "Минерална вата",
    "sound_insulation_value": "Акустична изолация (дБ)",
    "sound_proof": "Акустична изолация",
    "back_to_offers": "Върнете се към офертите",
    "support": "Тип основа",
    "finishing": "Завършване",
    "imported_file": "Файлът е обработен успешно",
    "interax": "Междуосово разстояние",
    "price": "Цена (EUR)",
    "sap_code": "SAP код",
    "product_name": "Име на продукта",
    "amount": "Kоличество",
    "square_meter": "кв.м.",
    "unit_of_measure": "М.Ед.",
    "project_info": "Информация за обекта",
    "joint_length": "Дължина на горна плъзгаща връзката",
    "boq_code": "Име на КС",
    "system_name": "Име на системата",
    "offer_details": "Детайли на офертата",
    "compatible_offers": "Оферти, които отговарят на вашите критерии",
    "choose_system": "Изберете типа на системата",
    "face1Plate1": "Страна 1: Гипсокартон 1",
    "face1Plate2": "Страна 1: Гипсокартон 2",
    "face1Plate3": "Страна 1: Гипсокартон 3",
    "face1Plate4": "Страна 1: Гипсокартон 4",
    "face1Plate5": "Страна 1: Гипсокартон 5",
    "face2Plate1": "Страна 2: Гипсокартон 1",
    "face2Plate2": "Страна 2: Гипсокартон 2",
    "face2Plate3": "Страна 2: Гипсокартон 3",
    "face2Plate4": "Страна 2: Гипсокартон 4",
    "more_details": "Повече детайли тук",
    "save_offer": "Запазете офертата",
    "interaxSustineri": "Междуосово разстояние за основната конструкция",
    "consumuri": "Необходими материали",
    "prindere_superioara": "Количествена сметка за горно закрепване",
    "surface": "Повърхност",
    "delete_offer_title": "Наистина ли искате да изтриете тази оферта?",
    "delete_offer_description": "След като я изтриете, вашата оферта вече няма да се запазва",
    "deleted_session_offer": "След като я изтриете, вашата оферта вече няма да се запазва",
    "deleted_offer": "Изтрита оферта",
    "delete_session_title": "Наистина ли искате да изтриете този обект?",
    "delete_session_description": "След като го изтриете, вашият обект вече няма да бъде запазен",
    "company": "Организация",
    "company_register": "Име на компанията, която представлявате",
    "project_code": "Код на проекта Siniat",
    "job": "Обхват на дейността",
    "contact_person": "Лице за контакт",
    "email": "Email",
    "objective": "Име на строителния обект",
    "type_objective": "Вид строителен обект",
    "type_objective_option_1": "Жилищен блок",
    "type_objective_option_2": "Офис сграда",
    "type_objective_option_3": "Промишлена сграда или склад",
    "type_objective_option_4": "Търговски ценър",
    "type_objective_option_5": "Хотел",
    "type_objective_option_6": "Болница",
    "type_objective_option_7": "Развлечения (басейн, кино, концертна зала и др.)",
    "type_objective_option_8": "Образование",
    "location": "Страна",
    "description": "Описание",
    "validation_date": "Валидна до:",
    "nr": "Референтен номер",
    "delete_session": "Изтриване на обект",
    "download_session": "Изтеглете обект",
    "view_session_offers": "Вижте оферти за обекта",
    "edit_session": "Редактиране на обекта",
    "create_new_session": "Създайте нов обект",
    "new_offer": "Създайте нова оферта",
    "edit_offer_plates": "Променете плоскостите, включени в офертата",
    "finish_edit_plates": "Приключване промяна на плоскостите, включени в офертата",
    "import_file": "Файл за обработка",
    "choose_import": "Изберете типа на изчислителния файл",
    "imports": "Импортирайте файлове за изчисление",
    "burglary_resistance": "Устойчивост на взлом",
    "number_of_offers": "Брой създадени оферти",
    "date_created": "Дата на създаване на офертата",
    "back_to_create_offer": "Обратно към генериране на оферти",
    "generate_offers": "Генерирайте съвместими оферти",
    "reset_offer": "Нулиране на полетата за оферта",
    "my_sessions": "Мои обекти",
    "manage_users": "Управление на потребители",
    "reports": "Отчети",
    "sessions_offers": "Обекти/Оферти",
    "save_session": "Запазете обекта",
    "download": "Изтегли",
    "download_file": "Изтегли файл",
    "download_users": "Изтегляне на списък",
    "next": "Продължи",
    "walls_s": "Един слой",
    "walls_d": "Два слоя",
    "walls_t": "Три слоя",
    "linnings_s": "Един слой",
    "linnings_d": "Два слоя",
    "linnings_t": "Три слоя",
    "linnings_q": "Четири слоя",
    "linnings_ei": "Обшивка EI180 min (5 плоскости)",
    "ceilings_plates_s": "Един слой",
    "ceilings_plates_d": "Два слоя",
    "ceilings_plates_t": "Три слоя",
    "ceilings_plates_q": "Четири слоя",
    "date_validation": "Дата на валидиране",
    "face": "страна",
    "exterior": "външен",
    "and": "и",
    "sistem_weight": "Тегло на системата",
    "montant_type": "Тип носещ профил",
    "upper_guiding": "Тип водещ профил, горна част",
    "lower_guiding": "Тип водещ профил, долна част",
    "plating_types": "Тип гипсокартон",
    "plating_type_face_a": "Тип гипсокартон, страна А",
    "plating_type_face_b": "Тип гипсокартон, страна B",
    "upper_support": "Тип основа, горна",
    "wool_type": "Тип изолация",
    "thickness_system": "Дебелина на системата",
    "ceilings_distance": "Разстояние до тавана",
    "ceilings_support": "Вид основа",
    "system_code": "Системен код",
    "protection_direction": "Посока на защита",
    "sound_insulation_label": "Акустична изолация",
    "plating_type_lower_face": "Тип гипсокартон, долна страна",
    "plating_type_upper_face": "Тип гипсокартон, горна страна",
    "main_structure": "Основна конструкция",
    "secondary_structure": "Вторична конструкция",
    "parameter_structure": "Тип водещ профил по периметъра",
    "interax_secondary": "Междуосово разстояние вторична конструкция",
    "type_intermediate_plate": "Вид междинен гипсокартон",
    "distance": "Разстояние между конструкциите",
    "structure_type": "Тип основа",
    "manage_account": "Моя акаунт",
    "edit_account": "Редактирайте информацията за акаунта",
    "finish_edit_account": "Запазете информацията за акаунта",
    "edit_password": "Редактиране на паролата",
    "edit_password_confirm": "Редактиране на потребителска парола",
    "edit_password_cancel": "Отменете редактирането на паролата",
    "based_plates": "Основна системна плоскост",
    "walls_s_asimetric": "Стени S - асиметрични",
    "walls_s_intermediar_d": "Двойна междинна",
    "walls_s_intermediar_t": "Тройна междинна",
    "intermediate_plate": "Междинен гипсокартон",
    "too_little": "Тази стойност е твърде ниска",
    "too_much": "Тази стойност е твърде висока",
    "max_opening": "Макс. отвор",
    "price_per_unit": "Цена (ЕUR)/кв.м",
    "rows_per_page": "Редове на страница:",
    "no_range": "Извън обхват!",
    "no_offers": "В момента няма създадена оферта",
    "passwords_must_match": "Паролата е различна! Моля, въведете отново",
    "description_popup": "Ето топ 6 най-евтини системи, конфигурирани според вашите изисквания. В колоните можете да намерите много от характеристиките на системите, така че да изберете най-подходящата за вас. Натиснете ОК за да продължите",
    "search": "Търсене",
    "less_table_cols": "Свиване на таблицата",
    "report": "Отчет",
    "import": "Импортиране",
    "materials": "Разход",
    "allowed_plates": "Допустими плоскости",
    "products": "Продукти",
    "ceilings_plates_s_tooltip": "Еднослойни тавани",
    "ceilings_plates_d_tooltip": "Двуслойни тавани",
    "ceilings_plates_t_tooltip": "Трислойни тавани",
    "ceilings_plates_q_tooltip": "Четирислойни тавани",
    "separative": "Вид преграждане",
    "linnings_noisy_double": "Двойна Noisy обшивка",
    "linnings_noisy_triple": "Тройна Noisy обшивка",
    "price_list": "Ценова листа",
    "attach_file": "Прикачете файл",
    "attach_file_for": "Прикачете файла за импортиране",
    "attach_file_by": "Прикачен файл от",
    "attach_file_last_timestamp": "Последната актуализация за __файл__ е извършена в",
    "attach_file_last_file": "Последно качен файл",
    "thickness": "Дебелина",
    "choose_project": "Изберете обект за тази оферта",
    "create_new_project": "Създайте нов обект",
    "quit": "Излез",
    "unspecified": "неуточнено",
    "product": "Продукт",
    "select_project": "Изберете обект",
    "systemType": "Тип на системата",
    "PDF_details": "PDF детайли",
    "DWG_details": "DWG детайли",
    "brochures_sistems": "Брошура за системите",
    "systemCode": "Системен код",
    "technical_characteristics": "Технически характеристики",
    "value": "Стойност",
	'more_details_here': "Повече детайли вижте тук",
	'create_offer_walls_d_simple': "Създайте оферта - Стени D - Единична облицовка",
	'create_offer_walls_d_double': "Създайте оферта - Стени D - Двойна облицовка",
	'create_offer_walls_d_triple': "Създайте оферта - Стени D - Тройна облицовка",
	'create_offer_walls_s_double': "Създайте оферта - Стени S - Двойна облицовка",
	'create_offer_walls_s_triple': "Създайте оферта - Стени S - Тройна облицовка",
	'create_offer_walls_s_asimetric': "Създайте оферта - Стени S - асиметрични",
	'create_offer_walls_s_double_intermediate': "Създайте оферта - Стени S - Двойна междинна",
	'create_offer_walls_s_triple_intermediate': "Създайте оферта - Стени S - Тройна междинна",
	'create_offer_walls_sl_simple': "Създайте оферта - Стени SL - Единична облицовка",
	'create_offer_walls_sl_double': "Създайте оферта - Стени SL - Двойна облицовка",
	'create_offer_walls_sl_triple': "Създайте оферта - Стени SL - Тройна облицовка",
	'create_offer_walls_sla_triple': "Създайте оферта - Стени SLA - Тройна облицовка",
	'create_offer_plating_fix_simple': "Създайте оферта - Обшивка с допълнително закрепване - Единична",
	'create_offer_plating_fix_double': "Създайте оферта - Обшивка с допълнително закрепване - Двойна",
	'create_offer_plating_fix_triple': "Създайте оферта - Обшивка с допълнително закрепване - Тройна",
	'create_offer_plating_fix_quadruple': "Създайте оферта - Обшивка с допълнително закрепване - Четворна",
    create_offer_plating_fix_ei: 'Създайте оферта - Обшивка с крепежи - EI180 min (5 плоскости)',
    'create_offer_plating_indep_simple': "Създайте оферта - Свободно стояща обшивка - Единична",
	'create_offer_plating_indep_double': "Създайте оферта - Свободно стояща обшивка - Двойна",
	'create_offer_plating_indep_triple': "Създайте оферта - Свободно стояща обшивка - Тройна",
	'create_offer_plating_indep_quadruple': "Създайте оферта - Свободно стояща обшивка - Четворна",
    'create_offer_plating_indep_ei': "Създайте оферта - Самоносеща обшивка - EI180 min (5 плоскости)",
    'create_offer_plating_liniar_double': "Създайте оферта - Шахтова стена - Двойна",
	'create_offer_plating_liniar_triple': "Създайте оферта - Шахтова стена - Тройна",
	'create_offer_plating_liniar_quadruple': "Създайте оферта - Шахтова стена - Четворна",
	'create_offer_plating_liniar_cv': "Създайте оферта - Шахтова стена - EI180 min (5 плоскости)",
	'create_offer_plating_sticking': "Създайте оферта - Обшивка чрез залепване",
    'create_offer_noisy_plating_fix_triple': "Създайте оферта - Обшивка Noisy със закрепване - тройна",
    'create_offer_noisy_plating_indep_double': "Създайте оферта - Свободно стояща обшивка Noisy - двойна",
    'create_offer_noisy_plating_indep_triple': "Създайте оферта - Свободно стояща обшивка Noisy - тройна",
    'create_offer_noisy_plating_uu_double': "Създайте оферта - Обшивка Noisy UU - двойна",
    'create_offer_noisy_plating_uu_triple': "Създайте оферта - Обшивка Noisy UU - тройна",
	'create_offer_ceiling_suspended_simple': "Създайте оферта - Окачени тавани - единични",
	'create_offer_ceiling_suspended_double': "Създайте оферта - Окачени тавани - двойни",
	'create_offer_ceiling_suspended_triple': "Създайте оферта - Окачени тавани - тройни",
	'create_offer_ceiling_suspended_quadruple': "Създайте оферта - Окачени тавани - четворни",
	'create_offer_ceiling_autoport_simple': "Създайте оферта - Самоносещи се тавани - единични",
	'create_offer_ceiling_autoport_double': "Създайте оферта - Самоносещи тавани - двойни",
	'create_offer_ceiling_autoport_triple': "Създайте оферта - Самоносещи тавани - тройни",
	'create_offer_ceiling_autoport_quadruple': "Създайте оферта - Самоносещи тавани - четворни",
    "download_offer": "Изтеглете офертата",
    "delete_offer": "Изтриване на офертата",
    "compatible_offers:": "Съвместими оферти",
    "country": "Държава",
    "dont_show_message": "Не показвайте това съобщение отново",
    "principal_structure": "Основна конструкция",
    "beam_type": "Тип греда",
    "change_county": "Промени Държавата",
    "save_county": "Запази Държавата",
    "county": "Държави",
    "access": "Достъп",
    "Da": "да",
    "Da, cu 1 strat": "да, с 1 слой",
    "Da, cu 2 straturi": "да, с 2 слоя",
    "Da, cu 3 straturi": "да, с 3 слоя",
    "Da, cu 4 straturi": "да, с 4 слоя",
    "Nu": "не",
    "RON": "ЕВРО",
    "Fara": "Без",
    "direction_0": "Неуточнено",
    "direction_1": "Защита отдолу нагоре",
    "direction_2": "Защита от горе до долу",
    "direction_3": "Защита и в двете посоки",
    "any": "Всички",
    "one_face": "Еднa страна",
    "one_exterior_face": "Една външна страна",
    "two_faces": "Две страни",
    "with_mineral_wool": "С минерална вата",
    "without_mineral_wool": "Без минерална вата",
    "without_finishing": "Без завършване",
    "with_finishing": "Със завършване",
    "simple_structure": "единична конструкция",
    "double_structure": "двойна конструкция",
    "one_face_cementex": "Един слой Cementex",
    "without": "Без",
    "vinclu": "ъгъл",
    "continue": "продължи",
    "Planseu beton armat": "Железобетон",
    "Tabla cutata de acoperis": "Стоманена ламарина",
    "walls_d_simple": "Стени D - Единична облицовка",
    "walls_d_double": "Стени D - Двойна облицовка",
    "walls_d_triple": "Стени D - Тройна облицовка",
    "walls_s_double": "Стени S - Двойна облицовка",
    "walls_s_triple": "Стени S - Тройна облицовка",
    "walls_s_double_intermediate": "Стени S - Двойна междинна",
    "walls_s_triple_intermediate": "Стени S - Тройна междинна",
    "walls_sl_simple": "Стени SL - Единична облицовка",
    "walls_sl_double": "Стени SL - Двойна облицовка",
    "walls_sl_triple": "Стени SL - Тройна облицовка",
    "walls_sla_triple": "Стени SLA - Тройна облицовка",
    "plating_fix_simple": "Обшивка с допълнително закрепване - Единична",
    "plating_fix_double": "Обшивка с допълнително закрепване - Двойна",
    "plating_fix_triple": "Обшивка с допълнително закрепване - Тройна",
    "plating_fix_quadruple": "Обшивка с допълнително закрепване - Четворна",
    "plating_fix_ei": "Обшивка с крепежи - EI180 min (5 плоскости)",
    "plating_indep_simple": "Свободно стояща обшивка - Единична",
    "plating_indep_double": "Свободно стояща обшивка - Двойна",
    "plating_indep_triple": "Свободно стояща обшивка - Тройна",
    "plating_indep_quadruple": "Свободно стояща обшивка - Четворна",
    "plating_indep_ei": "Самоносеща обшивка - EI180 min (5 плоскости)",
    "plating_liniar_double": "Шахтова стена - Двойна",
    "plating_liniar_triple": "Шахтова стена - Тройна",
    "plating_liniar_quadruple": "Шахтова стена - Четворна",
    "plating_liniar_cv": "Шахтова стена - EI180 min (5 плоскости)",
    "plating_sticking": "Обшивка чрез залепване",
    "noisy_plating_fix_triple": "Обшивка Noisy със закрепване - тройна",
    "noisy_plating_indep_double": "Свободно стояща обшивка Noisy - двойна",
    "noisy_plating_indep_triple": "Свободно стояща обшивка Noisy - тройна",
    "noisy_plating_uu_double": "Обшивка Noisy UU - двойна",
    "noisy_plating_uu_triple": "Обшивка Noisy UU - тройна",
    "ceiling_suspended_simple": "Окачени тавани - единични",
    "ceiling_suspended_double": "Окачени тавани - двойни",
    "ceiling_suspended_triple": "Окачени тавани - тройни",
    "ceiling_suspended_quadruple": "Окачени тавани - четворни",
    "ceiling_autoport_simple": "Самоносещи се тавани - единични",
    "ceiling_autoport_double": "Самоносещи тавани - двойни",
    "ceiling_autoport_triple": "Самоносещи тавани - тройни",
    "ceiling_autoport_quadruple": "Самоносещи тавани - четворни",
    "minute": "минути",
    "not_a_number": "Въведената стойност трябва да е число",
    "support_type_brida": "Конектор",
    "support_type_tirant": "Шпилка",
    "support_type_nonius": "Нониус",
    "support_type_tija": "шпилка M8",
    "support_type_racord_lemn": "Окачвач за дърво",
    "support_type_brida_ac": "Акустичен конектор",
    "support_type_beton": "Бетон",
    "support_type_zidarie": "Тухла",
    "support_type_tencuieli": "Мазилка",
    "support_type_altele": "Друга",
    "support_type_autoportant": "Самоносещ се",
    "bride": "връзки",
    "cu_rezistenta": "с устойчивост",
    "Brida": "Конектор",
    "Tirant": "Шпилка",
    "Nonius": "Нониус",
    "Tija M8": "шпилка M8",
    "Racord lemn": "Окачвач за дърво",
    "Brida AC": "Акустичен конектор",
    "Beton": "Бетон",
    "Zidarie": "Тухла",
    "Tencuieli": "Мазилка",
    "Altele": "Друга",
    "Autoportant": "Samonosivi",
    "walls_smart": "Стена Smart",
    "linings_smart": "Предстеннa обшивкa Smart",
    "ceilings_smart": "таван Smart",
    "create_offer_plating_smart_fix_simple": "Създайте оферта - Обшивка Smart с крепежи - единична",
    "create_offer_plating_smart_fix_double": "Създайте оферта - Обшивка Smart с крепежи - двойна",
    "plating_smart_fix_simple": "Обшивка Smart с крепежи - единична",
    "plating_smart_fix_double": "Обшивка Smart с крепежи - двойна",
    "create_offer_plating_smart_indep_simple": "Създайте оферта - Независима обшивка Smart - единична",
    "create_offer_plating_smart_indep_double": "Създайте оферта - Независима обшивка Smart - двойна",
    "plating_smart_indep_simple": "Независима обшивка Smart - единична",
    "plating_smart_indep_double": "Независима обшивка Smart - двойна",
    "create_offer_ceiling_smart_suspended_simple": "Създайте оферта - Окачен таван Smart - единичен",
    "create_offer_ceiling_smart_suspended_double": "Създайте оферта - качен таван Smart - двоен",
    "ceiling_smart_suspended_simple": "Окачен таван Smart - единичен",
    "ceiling_smart_suspended_double": "Окачен таван Smart - двоен",
    "create_offer_ceiling_smart_autoport_simple": "Създайте оферта - самоносещи Smart тавани - единичен",
    "create_offer_ceiling_smart_autoport_double": "Създайте оферта - самоносещи Smart тавани - двойна",
    "ceiling_smart_autoport_simple": "самоносещи Smart тавани - единичен",
    "ceiling_smart_autoport_double": "самоносещи Smart тавани - двойна",
    "create_offer_plating_smart_sticking": "Създайте оферта - Залепена Smart обшивка",
    "plating_smart_sticking": "Залепена Smart обшивка",
    create_offer_walls_smart_simple: "Стена Smart - единична обшивка",
    create_offer_walls_smart_double: "Стена Smart - двойна обшивка",
    "walls_smart_simple": "Стена Smart - единична обшивка",
    "walls_smart_double": "Стена Smart - двойна обшивка",
    "nida": "Nida",
    "smart": "Smart",
    "unit_mm": "мм",
    "unit_cm": "см",
    "unit_m": "м",
    "unit_mp": "м2",
    "unit_kg": "кг",
    "unit_kg_per_mp": "кг/м2",
    "unit_db": "дБ",
    "unit_min": "мин",
    "unit_max": "макс",
    "select": "Изберете",
    "download_selection": "Изтеглете избора",
    "404": "Съжаляваме, но страницата не е достъпна"
};